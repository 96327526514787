import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function EventDeclined(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="96px" height="96px" {...props}>
      <path d="M10.069,19H5V8h14l0,2.069c0.699,0.087,1.369,0.262,2,0.518V5c0-1.103-0.897-2-2-2h-1V1h-2v2H8V1H6v2H5C3.897,3,3,3.897,3,5v14c0,1.103,0.897,2,2,2h5.587C10.331,20.369,10.156,19.699,10.069,19z" />
      <path d="M14.053,14.963L13.9,14.391l2.1-1.718l-2.736-0.255L12.182,10L11.1,12.418l-2.736,0.255l2.036,1.718L9.827,17l2.355-1.4l1.146,0.664C13.504,15.79,13.75,15.356,14.053,14.963z" />
      <path fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="2" d="M21.5 14.5L15 21M18 13A5 5 0 1 0 18 23 5 5 0 1 0 18 13z" />
    </SvgIcon>
  );
}