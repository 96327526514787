import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class BusinessApiInvoker extends Component {

	static getFinanciers(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/financiers`, callback, callerror);
	}

	static postCustomersFinanciers(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/customers-financiers`, body, callback, callerror);
	}

	static getCustomersFinanciers(personId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customers/${personId}/financiers`, callback, callerror);
	}

	static deleteCustomersFinanciers(personId, financierId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/customers-financiers/${personId}/${financierId}`, callback, callerror);
	}

	static getServiceProviders(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/serviceProviders`, callback, callerror);
	}

}