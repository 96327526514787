import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SweetAlert from "react-bootstrap-sweetalert";
import './Confirm.css';

class Confirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alert: null,
        }
    }

    componentWillReceiveProps(next) {
        if (next.show === false || next.showWithoutConfirmation === false || next.error === false || next.showConfirmWithText === false || next.success === false) {
            this.setState({ alert: null });
        }
        if (next.show) {
            this.confirmAlert();
        }
        if (next.showWithoutConfirmation) {
            this.confirmAlertWithoutConfirmation();
        }
        if (next.success) {
            this.successDelete();
        }
        if (next.error) {
            this.unsuccessDelete(next)
        }
        if (next.showConfirmWithText) {
            this.showConfirmWithText();
        }
    }

    delete() {
        this.props.onConfirm();
    }

    unsuccessDelete(next) {
        const messageError = next.messageDeletedUnsuccess ? `${next.messageDeletedUnsuccess}` : `${this.props.t(`${this.props.errorBodyText}`)} ${this.props.text || ''}`;
        const alert = (
            <SweetAlert
                closeOnClickOutside={this.props.closeOnClickOutside}
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={this.props.t(`${this.props.errorTitle}`)}
                onConfirm={() => {
                    this.hideAlert()
                    this.props.onClose()
                }}
                onCancel={() => {
                    this.hideAlert()
                    this.props.onClose()
                }}
                confirmBtnCssClass={"btn success"}
            >
                {messageError}
            </SweetAlert>
        );
        this.setState({ alert });
    }

    successDelete() {
        const alert = (
            <SweetAlert
                customClass={this.props.customClass}
                closeOnClickOutside={this.props.closeOnClickOutside}
                success
                style={{ display: "block", marginTop: "-100px" }}
                title={this.props.t(`${this.props.successTitle}`)}
                onConfirm={() => {
                    this.props.onSuccess()
                    this.props.onClose()
                    this.hideAlert()
                }}
                onCancel={() => {
                    this.hideAlert()
                    this.props.onClose()
                }}
                confirmBtnCssClass={"btn success"}
                customButtons={this.props.customButtons}
                showCancel={this.props.showCancel}
                showConfirm={this.props.showConfirm}
            >
                <div className={this.props.useCustomStyles && this.props.classes.successBodyTextContainer}>
                    <div className={this.props.useCustomStyles && this.props.classes.successBodyText}> {`${this.props.text || ""} ${this.props.t(`${this.props.successBodyText}`)}` } </div>
                    <div>
                        <div className={this.props.useCustomStyles && this.props.classes.successBodyTextSecondLine}> {
                            Array.isArray(this.props.successBodyTextSecondLine) ?
                                this.props.successBodyTextSecondLine.map(text => {
                                    return text
                                })
                                : this.props.successBodyTextSecondLine
                        }
                        </div>
                    </div>
                </div>
                <div className={this.props.useCustomStyles && this.props.classes.successBodyTextThirdLine}> {this.props.successBodyTextThirdLine} </div>
                {this.props.customButtons &&
                    <div className="customAlertButtons">
                        {this.props.customButtons}
                    </div>
                }
            </SweetAlert>
        );

        this.setState({ alert });
    }

    confirmAlert() {
        const alert = (
            <SweetAlert
                customClass={this.props.customClass}
                closeOnClickOutside={this.props.closeOnClickOutside}
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={this.props.title}
                onConfirm={() => this.delete()}
                onCancel={() => {
                    this.hideAlert()
                    this.props.onClose()
                }}
                confirmBtnCssClass={"btn success"}
                cancelBtnCssClass={"btn danger"}
                confirmBtnText={this.props.t(`${this.props.confirmBtnText}`)}
                cancelBtnText={this.props.t(`${this.props.cancelBtnText}`)}
                customButtons={this.props.customButtons}
                showCancel={this.props.showCancel}
                showConfirm={this.props.showConfirm}
            >
                <p>{`${this.props.t(`${this.props.confirmBodyText}`)} ${this.props.text || ""}.`}</p>
                {this.props.confirmBodyTextSecondLine &&
                    (<><br /><p>{`${this.props.confirmBodyTextSecondLine}`}</p></>)
                }
                {this.props.customButtons &&
                    <div className="customAlertButtons">
                        {this.props.customButtons}
                    </div>
                }
            </SweetAlert>
        );

        this.setState({ alert });
    }

    /* aca bien */
    showConfirmWithText() {
        const {t} = this.props;
        const alert = (
            <SweetAlert
                customClass={this.props.customClass}
                closeOnClickOutside={this.props.closeOnClickOutside}
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={this.props.title || t(`${this.props.confirmTitle}`)}
                onConfirm={() => this.props.onConfirm()}
                onCancel={() => {
                    this.hideAlert()
                    this.props.onClose()
                }}
                confirmBtnCssClass={"btn success"}
                cancelBtnCssClass={"btn danger"}
                confirmBtnText={t(`${this.props.confirmBtnText}`)}
                cancelBtnText={t(`${this.props.cancelBtnText}`)}
                customButtons={this.props.customButtons}
                showCancel={this.props.showCancel}
                showConfirm={this.props.showConfirm}
            >
                <div className={this.props.classes.confirmBodyTextContainer}>
                    <div className={this.props.classes.confirmBodyText}> { this.props.message ? this.props.message : this.props.confirmBodyText} </div>
                    <div className={this.props.classes.confirmBodyTextSecondLine}>  {
                        Array.isArray(this.props.confirmBodyTextSecondLine) ?
                            this.props.confirmBodyTextSecondLine.map(text => {
                                return <div>
                                    {text}
                                </div>
                            })
                            : this.props.confirmBodyTextSecondLine
                        }
                    </div>
                </div>
                {this.props.customButtons &&
                    <div className="customAlertButtons">
                        {this.props.customButtons}
                    </div>
                }
            </SweetAlert>
        );

        this.setState({ alert });
    }

    confirmAlertWithoutConfirmation() {
        const alert = (
            <SweetAlert
                customClass={this.props.customClass}
                closeOnClickOutside={this.props.closeOnClickOutside}
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={this.props.title}
                onConfirm={() => {
                    this.delete()
                    this.hideAlert()
                    this.props.onClose()
                }}
                onCancel={() => {
                    this.hideAlert()
                    this.props.onClose()
                }}
                confirmBtnCssClass={"btn success"}
                cancelBtnCssClass={"btn danger"}
                confirmBtnText={this.props.t(`${this.props.confirmBtnText}`)}
                cancelBtnText={this.props.t(`${this.props.cancelBtnText}`)}
                customButtons={this.props.customButtons}
                showCancel={this.props.showCancel}
                showConfirm={this.props.showConfirm}
            >
                { `${this.props.t(`${this.props.confirmBodyText}`)} ${this.props.text}.`}
                {this.props.customButtons &&
                    <div className="customAlertButtons">
                        {this.props.customButtons}
                    </div>
                }
            </SweetAlert>
        );

        this.setState({ alert });
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    render() {
        return (
            <div>{this.state.alert}</div>
        )
    }
}

/**
 * Dont change this default props 
 * without changing the text props from where 
 * the confirm component was originally used
 */
Confirm.defaultProps = {
    confirmBtnText: "remove",
    cancelBtnText: "cancel",
    confirmTitle: "confirm.title",
    confirmBodyText: "confirm.body.text",
    successTitle: "success.title",
    successBodyText: "success.body.text",
    successBodyTextSecondLine: "",
    successBodyTextThirdLine: "",
    errorTitle: "error.title",
    confirmBodyTextSecondLine: "",
    messageDeletedUnsuccess: "",
    errorBodyText: "error.body.text",
    closeOnClickOutside: true,
    customButtons: null,
    customClass: '',
    showCancel: true,
    showConfirm: true,
}

Confirm.propTypes = {
    customIcon: PropTypes.string,
    confirmBodyText: PropTypes.string,
    confirmBodyTextSecondLine: PropTypes.string,
    successBodyText: PropTypes.string,
    successBodyTextSecondLine: PropTypes.string,
    successBodyTextThirdLine: PropTypes.string,
    messageDeletedUnsuccess: PropTypes.string,
    errorBodyText: PropTypes.string,
    confirmTitle: PropTypes.string,
    successTitle: PropTypes.string,
    errorTitle: PropTypes.string,
    confirmBtnText: PropTypes.string,
    cancelBtnText: PropTypes.string,
    text: PropTypes.string,
    show: PropTypes.bool,
    showWithoutConfirmation: PropTypes.bool,
    success: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    customButtons: PropTypes.element,
    customClass: PropTypes.string,
    showCancel: PropTypes.bool,
    showConfirm: PropTypes.bool,
}

const styles = {
    'confirmBodyText': {
        "marginBottom": "15px",
        "fontSize": "1em",
        "fontWeight": "300",
    },
    'confirmBodyTextSecondLine': {
        "fontSize": "1em",
        "opacity": "0.9"
    },
    'successBodyText': {
        "width": "25%",
    },
    'successBodyTextSecondLine': {
        "fontSize": "1em",
        "opacity": "0.9"
    },
    'successBodyTextThirdLine': {
        "text-align": "left",
        "margin": "15px 12% 0 12%",
        "fontSize": "14px",
        "opacity": "0.9"
    },
    "successBodyTextContainer": {
        "display": "flex",
        "margin": "0 10%",
    }
}

export default withStyles(styles)(withTranslation()(Confirm));
