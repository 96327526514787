import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import './DayPicker.css';

class DayPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            days: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
            selected: [],
            index: 0,
            originalSelected: [],
        }
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                originalSelected: this.props.value,
                selected: this.props.value,
            }, () => {
                this.props.value.map((v) => this.markAsSelected(v))
            });
        }
    }

    selectedDay(value) {
        if (this.props.disabled) {
            return;
        }
        //this.props.appointmentAction === 'assign_agenda' 
        if (this.props.appointmentAction === 'assign_agenda'  || this.props.insideModule) {
            const p = ([...this.state.selected, value]);
            const ps = p.filter((v, _, a) => a.indexOf(v) === a.lastIndexOf(v));
            if (ps.length > this.state.originalSelected.length && this.state.originalSelected.length > 0) {
                return;
            } else if (ps.length !== this.state.originalSelected.length && this.state.originalSelected.length > 0) {
                this.setState({
                    invalidCountDay: true,
                    errorCountday: this.props.t('dayPicker.errorCountday', { countDays: this.state.originalSelected.length }),
                });
            } else {
                this.setState({invalidCountDay: false, errorCountday: '' });
            }
        }
        this.setState({
            selected: [...this.state.selected, value]
        }, () => {
            this.filterValue();
            this.markAsSelected(value);
        });
    }

    markAsSelected(value) {
        if (document.getElementsByClassName(`day-${value}`)[this.props.indexArrayDays] && document.getElementsByClassName(`day-${value}`)[this.props.indexArrayDays].classList.contains('checked')) {
            document.getElementsByClassName(`day-${value}`)[this.props.indexArrayDays].classList.remove('checked');
        } else {
            document.getElementsByClassName(`day-${value}`)[this.props.indexArrayDays].classList.add('checked');
        }
    }

    filterValue() {
        const result = this.state.selected.filter((v, _, a) => a.indexOf(v) === a.lastIndexOf(v));
        this.setState({ selected: result }, () => {
            this.props.onChange(this.state.selected);
            this.props.invalidSelection(this.state.invalidCountDay || false);
        });
    }

    render() {
        return (
            <GridContainer>
                <GridItem className="day-picker" xs={12}>
                    {this.props.label && <GridItem xs={3} className="no-padding"><span className="picker-label">{this.props.label}</span></GridItem>}
                    <GridItem xs={9}>
                        {this.state.days &&
                            (<div>
                                {this.state.days.map(d => {
                                    return (
                                        <div className={`day-check day-${d}`} onClick={() => this.selectedDay(d)}>
                                            {d}
                                        </div>
                                    )
                                })}
                            </div>)
                        }
                        {this.props.invalid && (
                            <FormHelperText className="helper-error">
                                {this.props.errorText}
                            </FormHelperText>
                        )}
                        {this.state.invalidCountDay && (
                            <FormHelperText className="helper-error">
                                {this.state.errorCountday}
                            </FormHelperText>
                        )}
                    </GridItem>

                </GridItem>
            </GridContainer>
        )
    }
}

DayPicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.array,
    label: PropTypes.string,
    invalidSelection: PropTypes.func,
}

export default withTranslation()(DayPicker);