import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function Calendar(props) {
  return (
    <SvgIcon viewBox="0 0 172.5 186" width="96px" height="96px" {...props}>
      <path d="M130.3,26.3H120V11.6h-13.9v14.7H49.3V11.6H35.4v14.7h-9.9c-6.6,0-12,5.4-12,12v102.2c0,6.6,5.4,12,12,12h53.2 c-1.1-4.2-1.7-8.6-1.7-13.1c0-0.3,0-0.5,0-0.8H28.8V61H127v28.3c5.3,0,10.5,0.8,15.3,2.4V38.3C142.3,31.7,136.9,26.3,130.3,26.3z" />
      <path d="M127,103.5c-19.8,0-35.9,16.1-35.9,35.9c0,19.8,16.1,35.9,35.9,35.9c19.8,0,35.9-16.1,35.9-35.9 C162.9,119.6,146.8,103.5,127,103.5z M132.5,117.7l-1.3,29.9H123l-1.3-29.9H132.5z M131,160.6c-1.1,1-2.4,1.4-4,1.4 c-1.6,0-2.9-0.5-4-1.4c-1.1-1-1.6-2.2-1.6-3.7c0-1.5,0.5-2.7,1.6-3.7c1.1-1,2.4-1.4,4-1.4c1.6,0,2.9,0.5,4,1.4 c1.1,1,1.6,2.2,1.6,3.7C132.6,158.4,132.1,159.6,131,160.6z" />
    </SvgIcon>
  );
}