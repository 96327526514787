import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Assignment from '@material-ui/icons/Assignment';
import HotelIcon from '@material-ui/icons/Hotel';
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from "../Table/Table";
import Button from '../CustomButtons/Button';
import PersonApiInvoker from "../../api/PersonApiInvoker";
import './Customers.css';

class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            address: [],
            personAddress: [],
            loading: false,
        }
    }

    componentWillMount() {
        if (this.props.address) {
            this.setState({
                address: this.props.address,
            }, () => {
                this.props.customers && this.formatCustomers(this.props.customers);
            });
        }

        if (this.props.customers) {
            this.formatCustomers(this.props.customers);
        } else {            
            this.getCustomers();
        }
    }

    componentWillReceiveProps(next) {
        if (next.customers) {
            this.formatCustomers(next.customers);
        }

        if (next.address) {
            this.setState({ address: next.address });
        }
    }

    getAddress() {
        PersonApiInvoker.getAddress(data => {
            this.props.onGetAddress(data);
            if (!this.props.customers) {
                this.getCustomers();
            }
        }, null);
    }

    getCustomers() {
        this.setState({ loading: true });
        PersonApiInvoker.getCustomers(data => {
            this.setState({ loading: false });
            if (data && data.length) {
                this.props.onGetCustomers(data);
            }
        }, e => {
            this.setState({ loading: false });
        });
    }

    exportPdfCustomer = (customer) => {
        // change this number to generate more or less rows of data
    }

    formatCustomers = (customers) => {
        const { t } = this.props;
        const data = customers.map(c => {
            return {
                lastName: c.lastName,
                firstName: c.firstName,
                phone: c.phone && c.phone[0] ? c.phone[0].number : '',
                address: this.findPersonAddress(c.personId),
                id: c.id,
                email: c.email,
                locationName: c.locationName,
                provinceName: c.provinceName,
                age: moment().diff(c.birthdate, 'years', false),
                actions: (
                    <div className="customers-actions">
                        <Tooltip title={t('customers.tooltip.view')}>
                            <div>
                                <Button simple justIcon color="primary" authority="customers_view,customers_view_related" onClick={() => browserHistory.push({
                                    state: { mode: 'view'},
                                    pathname: `/ver-paciente/${c.personId}`
                                })}>
                                    <Assignment />
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        });
        this.setState({ customers: data });
    }

    handleLastName = (e) => {
        const lastName = e.target.value;

        const data = this.props.customers.filter(c => {
            return c.lastName.toLowerCase().indexOf(lastName.toLowerCase()) !== -1;
        });
        return this.formatCustomers(data);
    }

    handleFirstName = (e) => {
        const firstName = e.target.value;

        const data = this.props.customers.filter(c => {
            return c.firstName.toLowerCase().indexOf(firstName.toLowerCase()) !== -1
        });

        return this.formatCustomers(data);
    }

    handleDocument = (e) => {
        const doc = e.target.value;

        const data = this.props.customers.filter(c => {
            return c.id.toString().indexOf(doc) !== -1
        });

        return this.formatCustomers(data);
    }

    customerHistory(id) {
        browserHistory.push(`/historial/${id}`);
    }

    historiaClinicaList(id) {
        browserHistory.push(`/patient/${id}/medical-record`);
    }

    findPersonAddress(personId) {
        let address = '';
        if (this.state.personAddress && this.state.personAddress.length) {
            this.state.personAddress.map(pa => {
                if (pa.personId === personId) {
                    this.state.address.map(a => {
                        if (pa.addressId === a.addressId) {
                            address = a.address
                        }
                    })
                }
            });
        }
        return address;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="customers">
                <GridContainer >
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <HotelIcon />
                                </CardIcon>
                            </CardHeader>
                            <CardBody className="appointments-content-body filtrable-table">
                                <Table
                                    striped
                                    filterable
                                    loading={this.state.loading}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        { Header: t("customers.table.first_name"), accessor: 'firstName' },
                                        { Header: t("customers.table.last_name"), accessor: 'lastName' },
                                        { Header: t("customers.table.id"), accessor: 'id' },
                                        { Header: t("customers.table.locationName"), accessor: 'locationName' },
                                        { Header: t("customers.table.provinceName"), accessor: 'provinceName' },

                                        { Header: t("customers.table.email"), accessor: 'email' },
                                        { Header: t("customers.table.actions"), accessor: 'actions', sortable: false, filterable: false }
                                    ]}
                                    tableData={this.state.customers}
                                    defaultPageSize={10}    
                                    colorsColls={["primary"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}


Customers.propTypes = {
    onGetCustomers: PropTypes.func.isRequired,
    onGetAddress: PropTypes.func.isRequired,
    onGetPersonAddress: PropTypes.func.isRequired,
    customers: PropTypes.array,
    address: PropTypes.array,
    personAddress: PropTypes.array,
}

export default withTranslation()(Customers);
