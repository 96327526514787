import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { browserHistory } from 'react-router';
import config from '../../config/config.js'
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Help from "@material-ui/icons/Help";

// core components
import Button from "../CustomButtons/Button.jsx";
import ImageUpload from "../CustomUpload/ImageUpload.jsx";

import adminNavbarLinksStyle from "../../assets/components/adminNavbarLinksStyle";
import "./Navbars.css";
import CustomDialog from "../CustomDialog/index.js";
import About from "../About/index.js";
import { withTranslation } from 'react-i18next';
import VerificationApiInvoker from "../../api/VerificationApiInvoker";
import LetterAvatar from "../Avatar/LetterAvatar/index.js";
import startCase from 'lodash/startCase';
import map from 'lodash/map';
import Cookies from 'universal-cookie';
import BaseInvoker from "../../api/BaseInvoker";

const baseInvoker = new BaseInvoker();

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openProfile: false,
    openAbout: false,
    openAboutModal: false,
    alertsLength: 0,
    alertsIgnored: [],
    alertsFailed: [],
    multipleSelect: [],
    companies: [],
    user: {},
    email: '',
    companySelect: "",
    subdomain: '',
    changingCompany: false,
    checking: false,
  };

  componentWillMount = () => {
    
    this.getAlerts()
    const { companies, onSelectedCompany } = this.props;

    if (companies && companies.length > 0) {

      const storedCompany = parseInt(localStorage.getItem('itlg_default_company_id'), 10);
      const companySelect = storedCompany && this.companyBelongsToUser(companies,storedCompany) ? storedCompany : companies[0].companyId;
      
      onSelectedCompany(companySelect);
      this.setState({
        companySelect,
        companies
      })
    } else {
      this.getCompanies()
    }
    
    this.setState({
      user: localStorage.getItem('username'),
      email: localStorage.getItem('email'),
    })
  }

  componentWillReceiveProps(next) {
    

    if (next.companies) {

      const storedCompany = parseInt(localStorage.getItem('itlg_default_company_id'),10);
      const companySelect = storedCompany && this.companyBelongsToUser(next.companies, storedCompany) ? storedCompany : next.companies[0].companyId;
      this.setState({
        companies: next.companies,
        companySelect,
      })
    }
    
  }

  handleClick = () => {
    if (this.state.alertsLength > 0) {
      this.setState({ open: !this.state.open });
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleProfileClick = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };
  handleProfileClose = () => {
    if (this.state.changingCompany) {
      this.setState({ changingCompany: false });
    } else {
      this.setState({ openProfile: false });
    }
  };

  handleClickAbout = () => {
    this.setState({ openAbout: !this.state.openAbout });
  };
  handleCloseAbout = () => {
    this.setState({ openAbout: false })
  }

  handleCompany = event => {

    const targetCompany = event.target.value;
  
    let company = this.state.companies.find(item => item.companyId == targetCompany);
    if (company) {
      
      fetch(`/api/setcompany?itlg_default_company_id=` + targetCompany, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(profile => {

          if (profile) {
            this.setState({
              changeButtonProps: true,
              companySelect: targetCompany,
              openProfile: false
            });
            this.props.onChangeCompany();
            localStorage.setItem('itlg_default_company_id', targetCompany);
            this.props.onSelectedCompany(targetCompany);
            localStorage.setItem('company', JSON.stringify(company));
            if (window.location.href.indexOf("/solicitudes") > -1) {
              window.location.reload()
            } else {
              browserHistory.push('/solicitudes')
            }
          }
          
      })
    }
  };

  handleAlert = () => {
    browserHistory.push('/alertas')
  }

  handleAbout = () => {
    this.setState({ openAboutModal: !this.state.openAboutModal })
  }

  closeAboutModal = () => {
    this.setState({ openAboutModal: false })
  }

  companyBelongsToUser = (companies,companyId) => {
    return companies && companies.length > 0 && map(companies, 'companyId').includes(companyId);
  }

  getAlerts = () => {
    VerificationApiInvoker.getVerificationErrors(data => {
      if (!data.message) {
        this.filterAlerts(data)
        this.setState({
          alertsLength: data.length,
        })
      }
    }, null)
  }
  
  getCompanies = () => {
    
    var url = "/api/profile"
    var company = localStorage.getItem('itlg_default_company_id')
    if (company) {
      url = url + "?itlg_default_company_id=" + company;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': `Bearer ${localStorage.getItem('hmm_token')}`,
      }
    })
      .then((response) => {
        if (response.status === 401) {

        } else {
          return response.text().then(function (text) {
            return text ? JSON.parse(text) : {}
          })

        }
      })

      .then(data => {

        if (data && !data.message && !data.error) {
          this.setState({
            checking: false
          })
          this.setCompanies(data)
        } else {
          this.setState({
            checking: true
          })
        }
      });
  }
  
  setCompanies(profile) {
    
    if (profile && profile.companies) {

      this.props.onGetCompanies(profile.companies)
      if (!localStorage.getItem('itlg_default_company_id')  && profile.companies && profile.companies.length > 0) {
        localStorage.setItem('itlg_default_company_id', JSON.stringify(profile.companies[0].companyId))
      } else {
        // TODO!!!!, porque esta esto???
        //localStorage.removeItem('itlg_default_company_id')
      }
    }
  }

  filterAlerts = (alerts) => {
    let failed = []
    let ignored = []
    alerts.map(a => {
      if (a.status === 'Erronea') {
        failed.push(a)
      } else {
        ignored.push(a)
      }
    })
    this.setState({
      alertsIgnored: ignored,
      alertsFailed: failed,
    })
  }

  logoutUser = () => {

    const cookies = new Cookies();
    localStorage.removeItem('username');
    //localStorage.removeItem('hmm_token');    
    cookies.remove("sessionActive");
    
    this.props.onLogout();    
    this.props.onClearData();
    browserHistory.push('/login');

    baseInvoker.post("/oauth/logout");
  }

  getCompanyName = () => {
    const { companies, selectedCompany } = this.props;
    let companyName = '';

    if (companies && companies.length > 0) {
      let companyIndex;

      companyIndex = companies.findIndex(item => item.companyId === selectedCompany);
      
      if (companyIndex !== -1) {
        companyName = companies[companyIndex].name
      }
    }
    
    return companyName;
  }

  componentDidMount() {
    const subdomain = window.location.host.split('.')[0];
    this.setState({
      subdomain,
    })
  }

  render() {
    const { t } = this.props
    const { classes, rtlActive } = this.props;
    const { open, openProfile, openAbout, openAboutModal, user, email, companies } = this.state;
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });

    return (
      <div id="rightMenuTopStyle" className={wrapper}>
        
        {/* About Menu */}
        <div className={`${managerClasses} about-menu`}>
          <Button
            color="transparent"
            justIcon
            aria-label="About"
            aria-owns={openAbout ? "about-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickAbout}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Help
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            {/* <Hidden mdUp implementation="css">
              <span onClick={this.handleClickAbout} className={classes.linkText}>
                {rtlActive ? "إعلام" : "About"}
              </span>
            </Hidden> */}
          </Button>
          <Popper
            open={openAbout}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="left"
            className={classNames({
              [classes.popperClose]: !openAbout,
              [classes.pooperResponsive]: true,
              [classes.pooperResponsiveAbout]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="about-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseAbout}>
                    <MenuList role="menu">
                      <MenuItem>
                        <a className="menu-item" rel="noopener noreferrer" href="https://hmmglobal.com/soporte/" target="_blank"> Soporte </a>
                      </MenuItem>
                      <MenuItem>
                        <a className="menu-item" rel="noopener noreferrer" href={`https://${this.state.subdomain}.hmmglobal.com/eula.html`} target="_blank"> Términos y condiciones de uso </a>
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleAbout}
                      >
                        <span className="menu-item" > {t("header.navbar.about")} </span>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        {/* Notifications 
        <div className={`${managerClasses} notifications-menu`}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
            authority="menu_alerts"
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            {
              this.state.alertsLength > 0 ?
                <span className={classes.notifications}>{this.state.alertsLength}</span> : ''
            }
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClick} className={classes.linkText}>
                {rtlActive ? "إعلام" : "Notification"}
              </span>
            </Hidden> 
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="left"
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true,
              [classes.pooperResponsiveNotifications]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      {this.state.alertsIgnored.length > 0 &&
                        <MenuItem
                          onClick={this.handleAlert}
                          className={dropdownItem}
                        >
                          {this.state.alertsIgnored.length + t("header.navbar.verifications.ignored")}
                        </MenuItem>
                      }
                      {this.state.alertsFailed.length > 0 &&
                        <MenuItem
                          onClick={this.handleAlert}
                          className={dropdownItem}
                        >
                          {this.state.alertsFailed.length + t("header.navbar.verifications.failed")}
                        </MenuItem>
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        * /}
        {/* Profile button */}
        <div className={`${managerClasses} profile-menu`}>
          <Button
            color="transparent"
            justIcon
            aria-label="Profile User"
            aria-owns={openProfile ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleProfileClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            {/* <Hidden mdUp implementation="css">
              <span onClick={this.handleProfileClick} className={classes.linkText}>
                {rtlActive ? "إعلام" : "Profile"}
              </span>
            </Hidden> */}
          </Button>
          <Popper
            open={openProfile}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleProfileClose}>
                    <MenuList role="menu">
                      <MenuItem
                        className={dropdownItem}
                      >
                        <div className={"current-user"}>

                          <div className={"user-avatar"}>
                            <div className={"avatar"}>
                              {
                                user && user.photo ? ( // FIXME: Once user profile is ready change this.
                                  <ImageUpload
                                    avatar
                                    photo={user.photo}
                                    addButtonProps={{
                                      color: "transparent",
                                    }}
                                    changeButtonProps={{
                                      color: "transparent",
                                    }}
                                    removeButtonProps={{
                                      color: "transparent",
                                    }}
                                />
                                ) : (
                                  <div className="letter-avatar">
                                    <LetterAvatar user={user}/>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div className="user-info">
                            <div className="data-name">
                              <strong>{user}</strong><br />
                              {email}<br />
                              <div>
                                {this.getCompanyName()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                      {companies && companies.length > 0 && (
                        <React.Fragment>
                          <hr className="divider-root divider-light" />
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              {t("header.navbar.select_company")}
                            </MenuItem>
                            <div className="companies-list">
                            {this.state.companies.length > 0 &&
                              this.state.companies.map((m) => (
                                <MenuItem
                                    onClick={this.handleCompany}
                                    selected={m.companyId === this.state.companySelect}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    key={m.companyId}
                                    value={m.companyId}
                                  >
                                  {startCase(m.name)}
                                </MenuItem>)
                              )
                            }
                            </div>
                          </FormControl>
                      </React.Fragment>
                      )}

                      <hr className="divider-root divider-light" />
                      <MenuItem
                        className={`${dropdownItem} light-grey text-right`}
                      >
                        <Button
                          aria-label="Cerrar Sesión"
                          onClick={() => this.logoutUser()}
                        >{t("header.navbar.logout")}</Button>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <CustomDialog maxWidth={'sm'} open={openAboutModal} onClose={this.closeAboutModal} title={t("header.navbar.about.title")}>
          <About />
        </CustomDialog>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  companies: PropTypes.any,
  onGetCompanies: PropTypes.func,
  onSelectedCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.number,
  setDefaultCompany: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
  onClearData: PropTypes.func.isRequired,
};

export default withStyles(adminNavbarLinksStyle)(withTranslation()(HeaderLinks));
