import { connect } from 'react-redux';
import { getCustomers } from '../actions/customers';
import { fetchModules, fetchPracticeTypes } from '../actions/newSchedule';
import { fetchPatologies, fetchComplexities, fetchBarthels } from '../actions/records'

import CustomerForm from '../components/NewSchedule/CustomerForm';
import ProviderForm from '../components/NewSchedule/ProviderForm';


const mapStateToProps = (state) => {
    return {
        customers: state.customers.customers,        
        modules: state.newSchedule.modules,
        practiceTypes: state.newSchedule.practiceTypes,
        complexities: state.records.complexities,
        barthels: state.records.barthels,
        patologies: state.records.patologies,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetComplexities: (complexities) => {
            dispatch(fetchComplexities(complexities))
        },

        onGetPatologies: (patologies) => {
            dispatch(fetchPatologies(patologies))
        },

        onGetBarthels: (barthels) => {
            dispatch(fetchBarthels(barthels))
        },
        onGetCustomers: (customers) => {
            dispatch(getCustomers(customers))
        },
        onFetchModules: (modules) => {
            dispatch(fetchModules(modules))
        },
        onFetchPracticeTypes: (practiceTypes) => {
            dispatch(fetchPracticeTypes(practiceTypes))
        }
    }
}

const CustomerFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerForm);

const ProviderFormService = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProviderForm);


export default {
    CustomerFormService,
    ProviderFormService,
};