import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import SelectInput from '../SelectInput'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'
import CardIcon from '../Card/CardIcon'
import CardHeader from '../Card/CardHeader'
import CustomInput from '../CustomInput/CustomInput'
import Button from '../CustomButtons/Button'
import Snackbar from '../Snackbar/Snackbar'
import PersonPinCircle from '@material-ui/icons/PersonPinCircle'
import AccessTime from '@material-ui/icons/AccessTime'
import Assignment from '@material-ui/icons/Assignment'
import AddLocation from '@material-ui/icons/AddLocation'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import PinBlue from '../../assets/img/pin_blue.png'
import PinGreen from '../../assets/img/pin_green.png'
import config from '../../config/config.js'
import PersonApiInvoker from "../../api/PersonApiInvoker";
import Itinerary from '../../assets/img/itinerary.svg'

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
} from 'react-google-maps'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'
import _ from 'lodash'
import './Routes.css'
import { withTranslation } from 'react-i18next'

const RegularMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={8}
            center={{ lat: props.location.lat, lng: props.location.lng }}
            defaultOptions={{
                scrollwheel: false,
            }}
        >
            {props.directions && (
                <DirectionsRenderer
                    directions={props.directions}
                    options={{ suppressMarkers: true }}
                />
            )}
            {props.waypoints &&
                props.waypoints.map((d, i) => {
                    return (
                        <Marker key={i}
                            className="routes-marker"
                            defaultLabel={{ text: (i + 1).toString(), fontSize: '15px', color:'#3C4858' }}
                            defaultIcon={
                                d.status.scheduleStatusId === 2
                                    ? {
                                        url: PinGreen,
                                        scaledSize: { width: 40, height: 40 },
                                        size: { width: 40, height: 40 },
                                        labelOrigin: new window.google.maps.Point(20, 15)
                                    }
                                    : {
                                        url: PinBlue,
                                        scaledSize: { width: 40, height: 40 },
                                        size: { width: 40, height: 40 },
                                        labelOrigin: new window.google.maps.Point(20, 15)
                                    }
                            }
                            position={d.location}
                            onClick={() => props.showWaypointDetail(d)}
                        />
                    )
                })}
        </GoogleMap>
    ))
)

class Routes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            routes: [],
            currentLocation: {},
            markers: [],
            directions: [],
            customers: [],
            employees: [],
            schedules: [],
            waypoints: [],
            selectedCustomer: '',
            selectedEmployee: '',
            alertOpen: false,
            alertColor: 'info',
            alertMessage: '',
            alert: null,
        }
    }

    componentWillMount() {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords
                this.setState({
                    currentLocation: {
                        lat: coords.latitude,
                        lng: coords.longitude,
                    },
                })
            })
        }

        if (this.props.customers) {
            this.setState({
                customers: this.props.customers,
            })
        } else {
            this.getCustomers()
        }

        if (this.props.employees) {
            this.setState({
                employees: this.props.employees,
            })
        } else {
            this.getEmployees()
        }
    }

    componentWillReceiveProps(next) {
        if (next.customers) {
            this.setState({
                customers: next.customers,
            })
        }

        if (next.employees) {
            this.setState({
                employees: next.employees,
            })
        }
    }

    showWaypointDetail(waypoint) {
        this.setState({
            currentLocation: waypoint.location,
            alert: (
                <SweetAlert
                    style={{
                        display: 'block',
                        marginTop: '-100px',
                        fontFamily: 'Roboto',
                    }}
                    title={waypoint.customer}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                >
                    <div style={{ textAlign: 'left' }}>
                        <p style={{ display: 'flex', alignItems: 'center' }}>
                            <PersonPinCircle /> {waypoint.address}
                        </p>
                        <p style={{ display: 'flex', alignItems: 'center' }}>
                            <AccessTime /> {waypoint.scheduleDateTime}
                        </p>
                        <p style={{ display: 'flex', alignItems: 'center' }}>
                            <Assignment /> {waypoint.description}
                        </p>
                    </div>
                </SweetAlert>
            ),
        })
    }

    hideAlert() {
        this.setState({
            alert: null,
        })
    }

    getCustomers() {
        PersonApiInvoker.getCustomers(data => {
            if (data && data.length) {
                this.props.onFetchCustomers(data)
            }
        }, null)
    }

    getEmployees() {
        //const type = 'PROFESSIONAL'
        PersonApiInvoker.getEmployees(data => {
            if (data && data.length) {
                this.props.onFetchEmployees(data)
            }
        }, null)
    }

    getSchedules(employeeId, selectedDate) {
        PersonApiInvoker.postEmployeeHojaRuta({
            personId: employeeId,
            date: `${moment(selectedDate).format('YYYY-MM-DD')}`
        }, data => {
            this.paintSchedules(data)
        }, null)
    }

    setDirection(schedules) {
        const DirectionsService = new window.google.maps.DirectionsService()
        if (schedules.length > 0) {
            DirectionsService.route(
                {
                    origin: schedules[0].location,
                    destination: schedules[schedules.length - 1].location,
                    waypoints: schedules,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({
                            directions: result,
                        })
                    } else {
                        console.error(`error fetching directions ${result}`)
                    }
                }
            )
        } else {
            this.openAlert(
                'danger',
                `Este profesional no tiene turnos el ${this.state.selectedDate.format(
                    'DD-MM-YYYY'
                )}.`
            )
        }
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        })

        setTimeout(() => {
            this.setState({ alertOpen: false })
        }, 2000)
    }

    paintSchedules(schedules) {
        let daySchedules = []
        let waypoints = []

        schedules.map(s => {

            daySchedules.push({
                location: new window.google.maps.LatLng(
                    s.address.latitude,
                    s.address.longitude
                ),
                stopover: true,
            })
            waypoints.push({
                location: new window.google.maps.LatLng(
                    s.address.latitude,
                    s.address.longitude
                ),
                address: s.address.address,
                description: s.appointmentDescription,
                status: s.scheduleStatus,
                scheduleDateTime: moment(s.scheduleDateTime).format('HH:mm'),
                customer: `${s.customer.firstName} ${s.customer.lastName}`,
            })
        }
        )

        daySchedules = _.orderBy(daySchedules, 'startDate', 'asc')
        this.setState({
            waypoints: waypoints,
        })
        this.setDirection(daySchedules)
    }

    findCustomerAddress() {
        let address
        this.state.customers.map(c => {
            if (this.state.selectedCustomer === c.personId) {
                address = c.address
                return
            }
        })
        return address
    }

    handleFilter(e) {
        if (this.state.selectedEmployee && this.state.selectedDate) {
            this.getSchedules(this.state.selectedEmployee, this.state.selectedDate)
        }
    }

    render() {
        const { t } = this.props;
        return (
            <GridContainer className="routes">
                {this.state.alert}
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <img className='svgwhite svgIcon svgInCard' alt="Medical Doctor" src={Itinerary}></img>
                            </CardIcon>
                            <GridContainer className="routes-filters">
                                <GridItem xs={12} sm={6}>
                                    <FormControl className="routes-dropdown">
                                        <SelectInput
                                            onSelectedValue={(value) => this.setState({ selectedEmployee: value })} 
                                            onGetOptionLabel={(value) => `${value.firstName} ${value.lastName}`}
                                            valueAccessor={"personId"}
                                            elements={this.state.employees}
                                            value={this.state.selectedEmployee}
                                            label={t('routes.input.professionals')}
                                            >
                                        </SelectInput>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={4} className="item-dateInput date-size">
                                    <CustomInput xs={12}
                                        type="date"
                                        text={t('routes.input.date')}
                                        placeholder={t('routes.input.date')}
                                        id="startDate"
                                        formControlProps={{
                                            fullWidth: false
                                        }}
                                        onChange={value => this.setState({ selectedDate: value })}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={2}>
                                    <Button
                                        block
                                        color="success"
                                        onClick={e => this.handleFilter(e)}
                                    >
                                        {t('routes.input.filter')}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            <RegularMap
                                location={this.state.currentLocation}
                                directions={this.state.directions}
                                waypoints={this.state.waypoints}
                                showWaypointDetail={this.showWaypointDetail.bind(this)}
                                googleMapURL="/googlemap"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={
                                    <div
                                        style={{
                                            height: `500px`,
                                            borderRadius: '6px',
                                            overflow: 'hidden',
                                            width: `100%`,
                                        }}
                                    />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

Routes.propTypes = {
    onFetchCustomers: PropTypes.func.isRequired,
    onFetchEmployees: PropTypes.func.isRequired,
    customers: PropTypes.array,
    employees: PropTypes.array,
}

export default withTranslation()(Routes);
