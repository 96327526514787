export const EMPLOYEETYPES = 'EMPLOYEETYPES';
export const FINANCIERS = 'FINANCIERS';
export const PROVINCIES = 'PROVINCIES';
export const EMPLOYEES = 'EMPLOYEES';


export function getEmployeeTypes(employeeTypes) {
    return {type: EMPLOYEETYPES, employeeTypes}
}

export function getFinanciers(financiers) {
    return {type: FINANCIERS, financiers}
}

export function getProvincies(provincies) {
    return {type: PROVINCIES, provincies}
}

export function getEmployees(employees) {
    return {type: EMPLOYEES, employees}
}