import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import ValidationInput from '../ValidationInput';
import Snackbar from '../Snackbar/Snackbar';
import config from '../../config/config';
import SelectInput from '../SelectInput';
import store from '../../store';
import PersonApiInvoker from "../../api/PersonApiInvoker";
import ButtonSpinner from '../ButtonSpinner';
import './CreatePhone.css';

class CreatePhone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            detail: '',
            number: '',
            phoneTypes: [],
            types: [],
            alertColor: '',
            alertOpen: '',
            alertMessage: '',
            phoneType: '',
            save: false,
            loading: false,
        }
    }

    componentWillMount() {
        if (this.props.phoneTypes && this.props.phoneTypes.length) {
            const phoneTypes = this.formatPhoneTypes(this.props.phoneTypes)
            this.setState({
                phoneTypes,
                types: this.props.phoneTypes,
            })
        } else {
            this.getPhoneTypes()
        }

        if (this.props.edit) {
            this.setState({
                detail: this.props.edit.detail,
                number: this.props.edit.number,
                phoneType: this.props.edit.phoneType.phoneTypeId,
            })
        }
    }

    componentWillReceiveProps(next) {
        if (next.phoneTypes) {
            const phoneTypes = this.formatPhoneTypes(next.phoneTypes)
            this.setState({
                phoneTypes,
                types: next.phoneTypes,
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            name: '',
            detail: '',
            number: '',
            phoneType: '',
            phoneTypes: [],
            types: [],
            alertColor: '',
            alertOpen: '',
            alertMessage: '',
            save: false,
            loading: false,
        })
    }

    formatPhoneTypes(phoneTypes) {
        const result = [];
        phoneTypes.map(p => {
            result.push({
                id: p.phoneTypeId,
                value: p.name,
            })
        })
        return result;
    }

    getPhoneTypes() {
        PersonApiInvoker.getPhoneTypes(phoneTypes => {
            this.props.onGetPhoneTypes(phoneTypes);
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
    }

    savePhone() {
        if (this.props.edit) {
            this.patchPhone();
        } else {
            this.postPhone();
        }
    }

    postPhone() {
        this.setState({ save: true, loading: true });
        if (this.state.detail && this.state.phoneType && this.state.number && this.state.number.trim().length > 0
        && this.state.detail && this.state.detail.trim().length > 0
        ) {
            PersonApiInvoker.postPersonsNewPhone(this.props.personId, {
                detail: this.state.detail.trim(),
                number: this.state.number.trim(),
                phoneType: this.findPhoneType(this.state.phoneType),
                personId: this.props.personId
            }, data => {
                this.setState({ loading: false });
                if (data.phoneId) {
                    this.openAlert('success', 'Teléfono agregado con éxito.')
                    this.props.onPhoneSubmited(data)
                } else {
                    this.openAlert('danger', data.message)
                }
            }, error => {
                this.openAlert('danger', (error ? error.toString() : "Error"))
                this.setState({ loading: false });
            })
        } else {
            this.openAlert('warning', 'Hay campos requeridos sin información. Complete todos los datos requeridos.');
            this.setState({ loading: false });
        }
    }

    patchPhone() {
        this.setState({ save: true, loading: true });
        if (this.state.detail && this.state.phoneType && this.state.number && this.state.number.trim().length > 0
        && this.state.detail && this.state.detail.trim().length > 0
        ) {
            PersonApiInvoker.postPersonsPhone(this.props.personId, this.props.edit.phoneId, {
                phoneId: this.props.edit.phoneId,
                detail: this.state.detail.trim(),
                number: this.state.number.trim(),
                phoneType: this.findPhoneType(this.state.phoneType),
                personId: this.props.personId
            }, data => {
                this.setState({ loading: false });
                if (data.phoneId) {
                    this.openAlert('success', 'Teléfono agregado con éxito.')
                    this.props.onPhoneSubmited(data)
                } else {
                    this.openAlert('danger', data.message)
                }
            }, error => {
                this.setState({ loading: false });
                this.openAlert('danger', (error ? error.toString() : "Error"));
            })
        } else {
            this.openAlert('warning', 'Hay campos requeridos sin información. Complete todos los datos.')
        }
    }

    findPhoneType(id) {
        let phoneType = {}
        this.state.types.map(t => {
            if (t.phoneTypeId === id) {
                phoneType = t
                return;
            }
        })
        return phoneType;
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        })

        setTimeout(() => {
            this.setState({ alertOpen: false })
        }, 2000)
    }

    render() {
        const { t } = this.props;

        return (
            <GridContainer xs={12}>
                <GridItem xs={12} sm={6}>
                    <ValidationInput type="text" text="Descripción *" value={this.state.detail} onChangeValue={(value) => this.handleValue(value, 'detail')} invalid={(this.state.detail === '' || this.state.detail.trim().length == 0)  && this.state.save}  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <SelectInput label="Tipo *" elements={this.state.phoneTypes} value={this.state.phoneType} onSelectedValue={(value) => this.handleValue(value, 'phoneType')} invalid={this.state.phoneType === '' && this.state.save} errorText={t('error.required')}/>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <ValidationInput type="text" text="Número de Teléfono *" value={this.state.number} onChangeValue={(value) => this.handleValue(value, 'number')} invalid={(this.state.number === '' || this.state.number.trim().length == 0) && this.state.save} />
                </GridItem>
                <GridItem className="base-font" xs={12}>
                    <p className="required-text">
                        <small>*</small> Campos requeridos
                    </p>
                </GridItem>
                <hr className="divider-root divider-light" />
                <GridItem xs={12}>
                    <ButtonSpinner
                        className="create-address-submit"
                        onClick={() => this.savePhone()}
                        disabled={this.state.loading}
                        label="Guardar teléfono"
                        labelLoading="Guardando teléfono"
                        loading={this.state.loading}
                        typeButton="submit"
                        color="info"
                    />
                    <Snackbar
                        place="tr"
                        color={this.state.alertColor}
                        message={this.state.alertMessage}
                        open={this.state.alertOpen}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

CreatePhone.propTypes = {
    personId: PropTypes.number,
    phoneTypes: PropTypes.array,
    onGetPhoneTypes: PropTypes.func,
    onPhoneSubmited: PropTypes.func,
    edit: PropTypes.any,
}

export default withTranslation()(CreatePhone);
