import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link, browserHistory} from 'react-router';
import isEmpty from 'lodash/isEmpty';
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import Button from '../CustomButtons/Button';
import Snackbar from '../Snackbar/Snackbar';
import { withTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, FilledInput, IconButton, FormControl, CircularProgress } from '@material-ui/core';
import Person from '@material-ui/icons/Person';
import VpnKey from '@material-ui/icons/VpnKey';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import pwdBy from '../../assets/img/glin-white.png';
import logoMobility from '../../assets/img/login/logo-hmm-mobility.png';

import LetterAvatar from '../Avatar/LetterAvatar';
// import Cookies from 'universal-cookie';
import './Login.css';
import { Alert } from '@material-ui/lab';

class Login extends Component {
    profileTimeOut;

    constructor(props) {
        super(props);
        this.state ={
            loading: false,
            disableInteractions: false,
            alertColor: 'success',
            alertMessage: '',
            alertOpen: false,
            checking: false,
            rememberMe: false,
            showPassword: false,
            profile: {}
        };
    }

    componentWillMount() {

        /*const cookies = new Cookies(); 
        if (cookies.get("sessionActive")) {
            this.setState({
                checking: true,
            })
        }*/
        clearTimeout(this.profileTimeOut);
    }

    /*tokenRefreshed() {
        this.setState({
            checking: false,
        })
        browserHistory.push('/solicitudes')
    }*/

    handleLogin = () => {
        const { t } = this.props;
        this.setState({
            disableInteractions: true,
            loading: true,
        })
        
        fetch(`/api/auth/token`, {
            method: 'POST',            
            body:JSON.stringify({
                username:"dpicatto1@gmail.com", 
                password:"123456"
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        })
        .then(response => response.json())
        .then(data => {
            if (!data.error) {
                const user = {
                    username: document.getElementById('username').value,
                    access_token: data.access_token,
                    refresh_token: data.refresh_token
                }
                fetch(`/api/profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then(profile => {
                    if (profile) {
                        this.setState({profile}, () => {
                            this.profileTimeOut = setTimeout(() => {
                                this.setState({
                                    disableInteractions: false,
                                    loading: false,
                                });
        
                                //this.props.onGetCompanies(profile.companies);
                                if (profile.companies && profile.companies.length > 0) {
                                    localStorage.setItem('company', JSON.stringify(profile.companies[0]));
                                    localStorage.setItem('itlg_default_company_id', profile.companies[0].company_id);
                                }
                                localStorage.setItem('user_id', profile.userId);
                                localStorage.setItem('username', `${profile.firstName} ${profile.lastName}`);
                                localStorage.setItem('email', profile.email);
                                localStorage.setItem('APP_VERSION', profile.APP_VERSION);
                                localStorage.setItem('RELEASE_DATE', profile.RELEASE_DATE);                                                                        

                                browserHistory.push('/solicitudes');
                            }, 3000);
                        });
                    }
                })
            } else {
                this.openAlert('danger', t('login.bad_credentials'))
                this.setState({
                    disableInteractions: false,
                    loading: false,
                });
            }
        })
        .catch(e => {
            this.setState({
                disableInteractions: false,
                loading: false,
            });
        })
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false })
        }, 2000);
    }

    handleRememberMe = event => {
        this.setState({ rememberMe: event.target.checked });
    }

    render() {        
        const { t } = this.props;
        return (
            <div>                
                <div>
                    <div className="wrapper">
                        <div className="fullpage">
                            <div className="login">
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={8}>
                                        <div className="hmm-logo">
                                            <img src={logoMobility} alt="HMM Logo" />
                                        </div>
                                        <form id='login-form' onSubmit={this.handleLogin}
                                         onKeyPress={({key}) => key === 'Enter' && this.handleLogin()}
                                         >
                                            <Card login className="login-card">
                                                <CardBody>
                                                    {this.state.loading && !isEmpty(this.state.profile)  ? (
                                                        <div className="loading-profile">
                                                            <CircularProgress className="circular-progress" />
                                                        </div>
                                                    ): (
                                                        <React.Fragment>
                                                            <FormControl variant="filled">
                                                                <FilledInput
                                                                    disabled={this.state.disableInteractions}
                                                                    id="username"
                                                                    inputProps={{
                                                                        disabled: this.state.disableInteractions,
                                                                        placeholder: t('login.user')
                                                                    }}
                                                                    type="text"
                                                                    startAdornment={<InputAdornment position="start"><Person /></InputAdornment>}
                                                                />
                                                            </FormControl>
                                                            <FormControl variant="filled">
                                                                <FilledInput
                                                                    disabled={this.state.disableInteractions}
                                                                    id="password"
                                                                    inputProps={{
                                                                        disabled: this.state.disableInteractions,
                                                                        placeholder: t('login.password')
                                                                    }}
                                                                    type={!this.state.showPassword ? 'password' : 'text'}
                                                                    startAdornment={<InputAdornment position="start"><VpnKey /></InputAdornment>}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                            onClick={() => this.setState({showPassword : !this.state.showPassword})}
                                                                                edge="end"
                                                                            >
                                                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </React.Fragment>
                                                    )}
                                                </CardBody>
                                                {!this.state.loading ? (
                                                     <CardFooter className="login-sign-in">                                                         
                                                        <Button
                                                            onClick={this.handleLogin}
                                                            className="signIn"
                                                            variant="contained"
                                                            disabled={this.state.disableInteractions}
                                                        >
                                                            {t("login.signin")}
                                                        </Button>
                                                         <div>
                                                             <FormControlLabel
                                                                 control={<Checkbox className="login-remember-me" checked={this.state.rememberMe} onChange={this.handleRememberMe} />}
                                                                 label={t('login.remember_me')}
                                                             />
                                                         </div>
                                                         <div
                                                             className="login-recover"
                                                             style={this.state.loading ? {
                                                                 pointerEvents: 'none'
                                                             }: { }}
                                                         >
                                                             <Link className="login-recover-link" to="/">{t("login.forgot")}</Link>
                                                         </div>
                                                     </CardFooter>
                                                ) : null}
                                            </Card>
                                            <Snackbar
                                                place="tr"
                                                color={this.state.alertColor}
                                                message={this.state.alertMessage}
                                                open={this.state.alertOpen}
                                            />
                                        </form>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <div className="login-footer powered-by">
                                <a href="https://glin-technologies.com/" rel="noopener noreferrer" target="_blank" title="Glin">
                                    <img src={pwdBy} alt="Glin"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        )
    }
}

Login.propTypes = {
    onUserLogged: PropTypes.func.isRequired,
    companies: PropTypes.array,
    onGetCompanies: PropTypes.func,
}

export default withTranslation()(Login);
