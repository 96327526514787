import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { Checkbox } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import './CheckInput.css';

class CheckInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <GridContainer className="check-input">
                <GridItem sm={9}>
                    <FormLabel className="label-horizontal--no-padding">
                    {this.props.text}
                    </FormLabel>
                </GridItem>
                <GridItem sm={2}>
                    <Checkbox 
                        onClick={(e) => this.props.onChangeValue(e.target.value)}
                        checked={this.props.checked}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

CheckInput.propTypes = {
    text: PropTypes.string,
    onChangeValue: PropTypes.func,
    checked: PropTypes.bool,
}

export default CheckInput;