import { Component } from 'react';
import BaseInvoker from "./BaseInvoker";

const urlInvoke = "/schedule";
const baseInvoker = new BaseInvoker();

export default class SchedulerInvoker extends Component {

	static postBookings(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/bookings`, body, callback, callerror);
	}
	
	/**
	 * API Call used to create an appointment without any booking's agenda
	 * @param {*} body 
	 * @param {*} callback 
	 * @param {*} callerror 
	 */
	static postAppointment(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/new-appointment`, body, callback, callerror);
	}
	
	static assignAgenda(appointmentId, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/new-schedule-to-appointment/${appointmentId}`, null, callback, callerror)
	}

	static postSaveBookings(appointmentBookingId, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/save-bookings/${appointmentBookingId}`, null, callback, callerror);
	}

	static postDynamicContent(type, id, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/${type}/${id}`, body, callback, callerror);	
	}
	static postDynamicContentOnlyCheck(type, id, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/${type}/${id}?only-check=true`, body, callback, callerror);	
	}
	
	static deleteBookings(appointmentBookingId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/delete-bookings/${appointmentBookingId}`, callback, callerror);
	}

	static deleteBookingsByArray(body, callback, callerror) {
		console.log(body);
		baseInvoker.delete(`${urlInvoke}/delete-bookings/`, body, callback, callerror);
	}

	static getAppointmentScheduleItems(appointmentId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/appointment/items-request/${appointmentId}`, callback, callerror);
	}


	static scheduleReasign(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/schedules-reasign/`, body, callback, callerror);
	}
	

}