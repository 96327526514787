import { EMPLOYEES_DETAIL, EMPLOYEE_DETAIL, EMPLOYEE_GEOGRAPHICDETAIL, UNMOUNT_EMPLOYEE} from "../actions/employees";
import { EMPLOYEE_TYPES, 
        CHECK_TYPE, DELETE_ITEM, 
        UNCHECK_ALL, 
        PUT_EMPLOYEETYPE, 
        EMPLOYEE_GEOGRAPHICS,
        GEOGRAPHICS, 
        PUT_EMPLOYEEGEOGRAPHIC, 
        DELETE_ITEMGEOGRAPHIC, 
        CHECK_GEOGRAPHIC, 
        UNCHECK_ALLGEOGRAPHIC
        } from '../actions/newEmployee';

const employees = (state = {}, action, root) => {
    switch(action.type) {
      
        case EMPLOYEES_DETAIL:
            let employees = action.employees
            return {...state, employees}
        case EMPLOYEE_DETAIL:
            let employee = action.employee
            return {...state, employee}
        case EMPLOYEE_GEOGRAPHICDETAIL:
            let geographicOfEmployee = action.geographicOfEmployee
            return {...state,
                employee: {
                    ...state.employee,
                    employeeGeographics: geographicOfEmployee 
                }
            }
        case EMPLOYEE_TYPES:
            let  employeesType = action.Types
            return {...state, employeesType}
        case EMPLOYEE_GEOGRAPHICS:
            let  employeesGeographics = action.geographics
            return {...state, employee: {
                ...state.employee,
                employeesGeographics
            }}
        case GEOGRAPHICS:
            let  geographics = action.geographics
            return {...state, geographics}
        case DELETE_ITEM:
            let item = action.itemId
            let listEmployeeTypes = state.employee && state.employee.employeeTypes || []
            return {
                    ...state,
                    employee: {
                        ...state.employee,
                        employeeTypes: listEmployeeTypes.filter(a => {
                        return a.employeeTypeId !== item})
                    }
                }
        case DELETE_ITEMGEOGRAPHIC:
            let itemg = action.geographicId
            let listEmployeeGeographic = state.employee && state.employee.employeesGeographics || []
            return {
                    ...state,
                    employee: {
                        ...state.employee,
                        employeesGeographics: listEmployeeGeographic.filter(a => {
                        return a.geographicZoneId !== itemg})
                    }
                }
        case PUT_EMPLOYEETYPE:
            let employeeToPut = action.employeType
            let listToPut = state.employee && state.employee.employeeTypes || []
            return  {
                ...state,
                    employee: {
                        ...state.employee,
                        employeeTypes: employeeToPut.concat(listToPut)
                    }
            }
        case PUT_EMPLOYEEGEOGRAPHIC:
            let geographicIds = action.geographic
            let newGeographicsList = state.geographics.filter(g =>  geographicIds.indexOf(g.geographicZoneId) > -1)
            let listToConcat = state.employee && state.employee.employeesGeographics || []
            return  {
                ...state,
                    employee: {
                        ...state.employee,
                        employeesGeographics: listToConcat.concat(newGeographicsList)
                    }
            }
        case UNCHECK_ALL:
            return {
                ...state,
                checkType: []

            }
        
        case UNCHECK_ALLGEOGRAPHIC:
            return {
                        ...state,
                        checkGeographic: []
        
            }
        

        case CHECK_TYPE:
            const checkType = action.Type
            const currArray = state.checkType || []
            let nextArray = []
            if(currArray.indexOf(checkType.employeeTypeId) < 0) {
                  nextArray = [
                    ...currArray,
                    checkType.employeeTypeId          ]
            } else {
                  nextArray = [
                    ...currArray,
                  ]
                  nextArray = nextArray.filter(id => id !== checkType.employeeTypeId)
                }
            return { ...state, checkType: nextArray }
        case CHECK_GEOGRAPHIC:
            const checkGeographic = action.geographic
            const currArrayg = state.checkGeographic || []
            let nextArrayg = []
            if(currArrayg.indexOf(checkGeographic.geographicZoneId) < 0) {
                    nextArrayg = [
                    ...currArrayg,
                    checkGeographic.geographicZoneId          ]
            } else {
                    nextArrayg = [
                    ...currArrayg,
                    ]
                    nextArrayg = nextArrayg.filter(id => id !== checkGeographic.geographicZoneId)
                }
            return { ...state, checkGeographic: nextArrayg }
        case UNMOUNT_EMPLOYEE:
            return { ...state, employee: [] }
        default:
            return state
    }
}



export default employees;
