import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import _ from "lodash";
import moment from 'moment';
import Drivers from '../../assets/img/driver_jeep.png';

import { CircularProgress, TextField } from '@material-ui/core';
import { Add, Person } from '@material-ui/icons';
import PhoneIcon from '@material-ui/icons/Phone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardIcon from '../Card/CardIcon';
import ValidationInput from '../ValidationInput';
import DateInput from '../DateInput';
import SelectInput from '../SelectInput';
import CustomDialog from '../CustomDialog';
import Snackbar from '../Snackbar/Snackbar';
import Address from '../Address/address';
import Phone from '../Phone';
import EmployeeType from './EmployeeType/EmployeeType';
import EmployeesService from '../../containers/EmployeesService';
import PhoneService from '../../containers/PhoneService';
import PersonApiInvoker from "../../api/PersonApiInvoker";
import { Address as AddressIcon, Briefcase, Location as GlobeLocation, QrCode } from '../../icons';
import ButtonSpinner from '../ButtonSpinner';
import './EditEmployee.css';
import memory from '../../config/memory';
import Table from "../Table/Table";

class EditEmployee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentTypes: [],
            documents: [],
            genderTypes: [],
            nationalities: [],
            name: '',
            lastName: '',
            document: '',
            documentType: '',
            licenseNumber: '',
            gender: '',
            nationality: '',
            email: '',
            birthDate: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            personId: '',
            openAddress: false,
            openPhone: false,
            openEmployeeType: false,
            openEmployeeGeographic: false,
            openFinanciers: false,
            deleteAlert: false,
            address: null,
            phone: null,
            employeeType: null,
            employeeGeographic: null,
            save: false,
            dataChanged: false,
            refresh: true,
            mode: this.props.location && this.props.location.state && this.props.location.state.mode,
            loading: false,
            driver: {
                "firstName": "",
                "lastName": "",
                "nationality": "Argentino/a",
                "documentType":
                    "DNI - Documento Nacional de identidad",
                "gender": "Masculino",
                "indetification": "",
                "email": ""
            },

        }
    }

    componentWillMount() {
        var driver = memory.drivers.filter(item => item.id == this.props.params.id);
        var vehicles = [];
        var geographics = [];
        var phones = [];
        var address = [];

        vehicles.push({
            marca: driver[0].marca,
            model: driver[0].model,
            type: driver[0].type,
            apto: driver[0].apto,
            year: driver[0].year,
            patente: driver[0].patente
        })
        phones.push({
            description: driver[0].description,
            typephone: driver[0].typephone,
            numberphone: driver[0].numberphone,
        })

        address.push({
            addresstype: driver[0].addresstype,
            street: driver[0].street,
            number: driver[0].number,
            city: driver[0].city,
            province: driver[0].province,
            main: driver[0].main
        })

        geographics.push({
            province: driver[0].province,
            city: driver[0].city,
            zone: driver[0].zone,
        })


        this.setState({
            driver: driver[0],
            vehicles: vehicles,
            geographics: geographics,
            address: address,
            phones: phones,

        })

    }

    componentWillReceiveProps(next) {
    }

    componentWillUnmount() {
        this.props.onUnmountEmployee();
    }

    formatDocumentTypes(documentTypes) {
        const result = documentTypes.map(d => {
            return {
                id: d.idTypeId,
                value: d.name + " - " + d.type
            }
        });
        return result;
    }

    getGenderTypes() {
        PersonApiInvoker.getGenderTypes(data => {
            this.setGenderTypesState(data);
        }, null);
    };

    getDocumentTypes() {
        PersonApiInvoker.getIdTypes(data => {
            this.props.onGetDocumentTypes(data);
        }, null);
    }

    findDocumentType(id) {
        let type = {};
        this.state.documents.map(d => {
            if (id === d.idTypeId) {
                type = d;
                return;
            }
        });
        return type;
    }

    setGenderTypesState(genderTypes) {
        const { t } = this.props;
        const genderTypesMap = {
            UNDEFINED: t('customers.new.gender.undefined'),
            MALE: t('customers.new.gender.male'),
            FEMALE: t('customers.new.gender.female'),
        }
        const result = genderTypes.map(genderType => {
            return {
                id: genderType,
                value: genderTypesMap[genderType],
            };
        });

        this.setState({ genderTypes: result });
    };

    getNationalities(findCustomer) {
        PersonApiInvoker.getNationalities(jsonResponse => {
            this.setState({
                nationalities: jsonResponse.map(nationalityItem => {
                    return {
                        id: nationalityItem.nationalityId,
                        name: nationalityItem.name
                    }
                })
            }, findCustomer);
        }, findCustomer);
    };

    findNationality() {
        const nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === this.state.nationality.id;
        });

        return {
            name: nationalityObj.name,
            nationalityId: nationalityObj.id,
        };
    };

    loadNationality(employee) {
        const employeeNationalityId = _.get(employee.nationality, 'nationalityId', '');
        const nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === employeeNationalityId;
        });

        return nationalityObj;
    }


    handleValue(value, state) {
        this.setState({
            [state]: value,
            dataChanged: true,
            refresh: false,
        });
    }


    render() {
        const maxDate = moment().format('YYYY-MM-DD');
        const minDate = moment().subtract(120, 'years').format('YYYY-MM-DD');
        const { t, onDeleteItem } = this.props;
        var { alertColor, alertMessage, alertOpen,
            dataChanged, document, documentType, documentTypes, email, gender, genderTypes, loading,
            lastName, licenseNumber, mode, nationalities, nationality, name, personId, refresh, save,
        } = this.state;
        mode = "view";
        return (
            <GridContainer className="edit-customer employee">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <Person />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.profileData')}</h3>
                        </CardHeader>

                        <CardBody className="edit-customer-form">
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput className={(mode === 'view') ? 'viewmode' : ''} text={t('employee.new.first_name')} onChangeValue={(value) => this.handleValue(value, 'name')} value={this.state.driver.firstName} disabled={mode === 'view'} invalid={name === '' && save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput text={t('employee.new.last_name')} onChangeValue={(value) => this.handleValue(value, 'lastName')} value={this.state.driver.lastName} disabled={mode === 'view'} invalid={lastName === '' && save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput text={t('employee.new.nationality')} onChangeValue={(value) => this.handleValue(value, 'lastName')} value={this.state.driver.nationality} disabled={mode === 'view'} invalid={lastName === '' && save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput text={t('employee.new.id_type')} elements={documentTypes} onSelectedValue={(value) => this.handleValue(value, 'documentType')} value={this.state.driver.documentType} disabled={mode === 'view'} invalid={documentType === '' && save} />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput text={t('employee.new.identification')} type="text" onChangeValue={(value) => this.handleValue(value, 'licenseNumber')} value={this.state.driver.identification} disabled={mode === 'view'} invalid={licenseNumber === '' && save} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <ValidationInput className="viewmode" text={t('employee.new.gender')}
                                            value={this.state.driver.gender} disabled="true" />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <DateInput text={t('employee.new.birth_date')}
                                            minDate
                                            min={minDate}
                                            max={maxDate}
                                            onChangeValue={(value) => this.handleValue(value, 'birthDate')}
                                            disabled={mode === 'view'}
                                            value={this.state.driver.birthDate}
                                            invalid={this.state.birthdate ? this.state.birthdate <= maxDate : !this.state.birthDate && save}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ValidationInput
                                            text={t('employee.new.email')}
                                            type="email" onChangeValue={(value) => this.handleValue(value, 'email')}
                                            value={this.state.driver.email}
                                            disabled={mode === 'view'}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>

                    </Card>
                </GridItem>

                <GridItem xs={12} className="specialities-practices-view">
                    <Card className="employee-geographic-zones">
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <img src={Drivers} alt="Driver" width='32' />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.vehicles')}</h3>
                        </CardHeader>
                        <CardBody>
                            <Table
                                striped
                                filterable
                                tableHeaderColor="primary"
                                defaultPageSize={10}
                                tableHead={[
                                    { Header: t("employees.table.marca"), accessor: 'marca' },
                                    { Header: t("employees.table.model"), accessor: 'model' },
                                    { Header: t("employees.table.type"), accessor: 'type' },
                                    { Header: t("employees.table.apto"), accessor: 'apto' },
                                    { Header: t("employees.table.year"), accessor: 'year' },
                                    { Header: t("employees.table.patente"), accessor: 'patente' },
                                ]}
                                tableData={this.state.vehicles}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>
                    <Card className="employee-geographic-zones">
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <GlobeLocation />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.GeographicsZones')}</h3>
                        </CardHeader>
                        <CardBody>
                            <Table
                                striped
                                filterable
                                tableHeaderColor="primary"
                                defaultPageSize={10}
                                tableHead={[
                                    { Header: t("employees.table.province"), accessor: 'province' },
                                    { Header: t("employees.table.city"), accessor: 'city' },
                                    { Header: t("employees.table.zone"), accessor: 'zone' },
                                ]}
                                tableData={this.state.geographics}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>

                    <Card className="employee-adresses">
                        <CardHeader icon>
                            <CardIcon color="secondary">
                                <AddressIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.Addresses')}</h3>
                        </CardHeader>
                        <CardBody>
                            <Table
                                striped
                                filterable
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t("employees.table.addresstype"), accessor: 'addresstype' },
                                    { Header: t("employees.table.street"), accessor: 'street' },
                                    { Header: t("employees.table.number"), accessor: 'number' },
                                    { Header: t("employees.table.city"), accessor: 'city' },
                                    { Header: t("employees.table.province"), accessor: 'province' },
                                    { Header: t("employees.table.main"), accessor: 'main' },
                                ]}
                                tableData={this.state.address}
                                defaultPageSize={10}
                                colorsColls={["primary"]}
                            />

                        </CardBody>
                    </Card>

                    <Card className="employee-phones">
                        <CardHeader icon>
                            <CardIcon color="primary">
                                <PhoneIcon />
                            </CardIcon>
                            <h3 className="card-icon-title">{t('employee.new.Phones')}</h3>
                        </CardHeader>
                        <CardBody>
                            <Table
                                striped
                                filterable
                                tableHeaderColor="primary"
                                tableHead={[
                                    { Header: t("employees.table.description"), accessor: 'description' },
                                    { Header: t("employees.table.typephone"), accessor: 'typephone' },
                                    { Header: t("employees.table.numberphone"), accessor: 'numberphone' },
                                ]}
                                tableData={this.state.phones}
                                defaultPageSize={10}

                                colorsColls={["primary"]}
                            />


                        </CardBody>
                    </Card>

                    <Snackbar
                        place="tr"
                        color={alertColor}
                        message={alertMessage}
                        open={alertOpen}
                    />

                    {personId &&
                        <CustomDialog fullWidth title={t('employee.specialities.practices')} open={this.state.openEmployeeType} onClose={() => this.setState({ employeeType: null, openEmployeeType: false })}>
                            <EmployeesService.EmployeesTypeService personId={personId} edit={this.state.employeeType} onTypeSubmited={() => this.setState({ employeeType: null, openEmployeeType: false, refresh: true })} />
                        </CustomDialog>
                    }

                    {personId &&
                        <CustomDialog title={t('employee.new.GeographicsZones')} open={this.state.openEmployeeGeographic} onClose={() => this.setState({ employeeGeographic: null, openEmployeeGeographic: false })}>
                            <EmployeesService.EmployeesGeographicModal personId={personId} edit={this.state.employeeGeographic} onGeographicSubmited={() => this.setState({ employeeGeographic: null, openEmployeeGeographic: false, refresh: true })} />
                        </CustomDialog>
                    }

                    {personId &&
                        <CustomDialog title={t('employee.new.modal.address')} open={this.state.openAddress} onClose={() => this.setState({ address: null, openAddress: false })}>
                            <AddressService edit={this.state.address} personId={personId} onAddressSubmited={() => this.setState({ address: null, openAddress: false, refresh: true })} />
                        </CustomDialog>
                    }

                    {personId &&
                        <CustomDialog title={t('employee.new.modal.phone')} open={this.state.openPhone} onClose={() => this.setState({ phone: null, openPhone: false })} >
                            <PhoneService edit={this.state.phone} personId={personId} onPhoneSubmited={() => this.setState({ phone: null, openPhone: false, refresh: true })} />
                        </CustomDialog>
                    }
                </GridItem>

                <GridItem xs={12} className="text-center">
                    <Button className="centerButton" onClick={() => browserHistory.push(`/profesionales`)}>
                        {t('employee.new.back')}
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }
}

EditEmployee.defaultProps = {

}

EditEmployee.propTypes = {
    onGetDocumentTypes: PropTypes.func.isRequired,
    onGetEmployee: PropTypes.object,
}

export default withTranslation()(EditEmployee);
