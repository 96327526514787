
class Authority {
    static hasPermission = (authority) => {
        let result = true;
        let companyStr = localStorage.getItem('company');
        if (companyStr) {
            let company = JSON.parse(companyStr);
            if (company && company.authorities && authority) {
                var splitAuthority = authority.split(",")
                for (let item of splitAuthority) {
                    result = item ? company.authorities.includes(item) : true;
                    if (result) {
                        break;
                    }
                 }
            }
        }
        return result;
    }
}

export default Authority;

