import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import _, { isArray } from 'lodash';
import moment from 'moment';
import { Icon } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { RestaurantSharp, WarningOutlined } from '@material-ui/icons';
import Assignment from '@material-ui/icons/Assignment';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import ViewList from '@material-ui/icons/ViewList';
import CustomInput from '../../CustomInput/CustomInput';
import ValidationInput from '../../ValidationInput';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardFooter from '../../Card/CardFooter';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';


import CardIcon from '../../Card/CardIcon';
import Button from '../../CustomButtons/Button';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table';
import SchedulerService from '../../../containers/SchedulerService';
import ModalDetailServiceTraslado from '../Traslado/ModalDetailServiceTraslado.js';
import memory from '../../../config/memory';
import EventWithIcon from '../../EventWithIcon/EventWithIcon';
import DialogTraslado from '../Traslado/DialogTraslado.js';

import './AppointmentDetail.css';
import { Alert } from '@material-ui/lab';
require('moment/locale/es.js');

const localizer = momentLocalizer(moment)


class AppointmentDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      metadata: {
        medicalData: {},
        diagnostic: {}
      },
      cancellations: [],
      cancellationReason: '',
      confirmMetadataEdit: false,
      saveChangesDisabled: false,
      bookings: [],
      practiceModulesCopy: [],
      loading: false,
      openDetail: false,
      openConfirmCancel: false,
      openConfirmChanges: false,
      currentEvent: {},
      events: [],
      event_original: [],
      items: [],
      itemsCopy: [],
      updateErrors: {},
      status: "",
      alertErrorOpen: false,
      alertErrorMessage: "",
      alertOpen: false,
      alertStatus: "",
      appointment: null,
      showConfirmUndo: false,
      showConfirmSave: false,
      bookingErrors: [],
      alertMessageOpen: false,
      alertMessageColor: "danger",
      alertMessageMsg: "danger",
      rejecteds: [],
      calendarViewMode: "month",
      scheduleStatus: '',
      serviceProvider: null,
      confirmOpenNewAppointment: false,
      busy: false,
      services: [],
      openService: false,
    }
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    this.confirmUndo()
  }

  onUnload = (event) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  }

  componentDidMount() {

    memory.tempService = null;
    if (this.props.params.id) {
      var appointment = this.formatAppointment(this.props.params.id);
      if (appointment == null) {
        return;
      }
      var services = memory.tempService && memory.tempService.traslado ? [this.formatService(memory.tempService, appointment)] : []
      if (this.props.params.id == 100 || this.props.params.id == 101) {
        appointment, services = this.fillSchedules(appointment, services)
      }
      console.log(services.startDate);
      this.setState({
        appointmentId: this.props.params.id,
        appointment: appointment,
        services: services
      }, () => {
        this.setState({
          openService: false
        })
      });
    }
  }

  onChangeView = () => {

  }

  fillSchedules = (appointment, services) => {

    try {
      var d = new Date();
      appointment.provider.dateStart = d.setDate(d.getDate() - 5);
      appointment.provider.dateEnd = d.setDate(d.getDate() + 30);

      appointment.services.startDate = appointment.provider.dateStart;
      services.startDate = d.setDate(d.getDate() - 5);

      var schedules = this.makeBookingsFromAppointment(appointment);
      appointment.schedules = schedules;
      return appointment, services;

    } catch (ex) {
      alert(JSON.stringify(appointment.provider));
    }



  }

  formatAppointment = (id) => {
    var appointment = memory.appointments.filter(item => item.appointmentId == id);
    if (appointment.length > 0) {
      memory.tempService = appointment[0].services;
      return appointment[0];
    }
    return null;
  }

  componentWillUnmount() {

  }

  componentWillMount() {
    window.addEventListener('unload', this.componentCleanup);
  }



  componentWillReceiveProps(next) {

  }


  setProvider = serviceProvider => {
    this.setState({ "serviceProvider": serviceProvider });
  }


  getProvider = () => {
    const {
      financier,
      customer,
      startDate,
      endDate,
    } = this.state.appointment;
    return {
      customerId: {
        customer: customer.personId
      },
      financier,
      provider: this.getServiceProviderOnEdit(this.state.items),
      dateStart: startDate,
      dateEnd: endDate
    }
  }

  getCustomer = () => {
    const {
      customer: { personId },
      address: { addressId },
    } = this.state.appointment;

    return {
      customer: personId,
      address: addressId
    }
  }


  onBack = () => {
    browserHistory.goBack();
  }

  setProviderF(value) {
    this.setState({
      provider: value,
    }, () => {

    });
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: (value.financierId) ? value.financierId : '',
      affiliateId: (value.affiliateId) ? value.affiliateId : '',
    });
  }


  getPlanQuantity(plan, startDate, endDatePlan, endDateAppointment, makeEvent) {
    var events = [];


    if (startDate == '') {
      return 0;
    }
    let endDate = endDateAppointment;
    if (!(endDate instanceof Date)) {
      endDate = moment(endDate, 'YYYY-MM-DD');
    }
    if (endDatePlan != null) {
      endDate = endDatePlan;
    }

    let quantity = 1;
    const daysTotal = moment(endDate).diff(startDate, 'days') + 1
    const every = plan.recurrence.every

    if (plan.recurrence.endType === 'OCURRENCES'
      && (plan.recurrence.finishOcurrences || plan.recurrence.ocurrences)
    ) {

      quantity = plan.recurrence.finishOcurrences || plan.recurrence.ocurrences

    } else {

      if (moment(startDate).diff(endDate, 'days') > 0) {
        return 0;
      }

      let currentDate = startDate;
      const endDatePlusOne = moment(endDate, 'YYYY-MM-DD').add(1, 'd');
      let e = every;
      let q = 0;

      if (plan.recurrence.recurrenceType === 'DAY') {

        while (!(moment(currentDate).isSame(endDatePlusOne, 'day')) && q < 100) {
          if (e == every) {
            q++;
            if (makeEvent) this.makeEvent(events, currentDate, q);

            e = 1;
          } else {
            e++;
          }
          currentDate = moment(currentDate).add(1, 'days');
        }
        quantity = q;

      } else if (plan.recurrence.recurrenceType === 'WEEK' || plan.recurrence.recurrenceType === 'INTRADAY') {

        let weekDays = plan.recurrence.weekRecurrence || plan.recurrence.weekDays;
        if (weekDays == null) {
          weekDays = '';
        }
        weekDays = typeof weekDays === 'string' ? weekDays.split(',') : weekDays
        let weekDaysSchedulePlan = []
        if (weekDays.includes('L')) { weekDaysSchedulePlan.push(1) }
        if (weekDays.includes('M')) { weekDaysSchedulePlan.push(2) }
        if (weekDays.includes('X')) { weekDaysSchedulePlan.push(3) }
        if (weekDays.includes('J')) { weekDaysSchedulePlan.push(4) }
        if (weekDays.includes('V')) { weekDaysSchedulePlan.push(5) }
        if (weekDays.includes('S')) { weekDaysSchedulePlan.push(6) }
        if (weekDays.includes('D')) { weekDaysSchedulePlan.push(0) }
        let yesterdary;
        while (!(moment(currentDate).isSame(endDatePlusOne, 'day'))) {
          if (e == every) {
            if (weekDaysSchedulePlan.includes(currentDate.day())) {
              if (plan.recurrence.recurrenceType === 'INTRADAY') {
                q = q + parseInt(plan.recurrence.quantityIntraday ? plan.recurrence.quantityIntraday : 1);
              } else {
                q++;
                if (makeEvent) this.makeEvent(events, currentDate, q);
              }
            }
          }
          yesterdary = currentDate;
          currentDate = moment(currentDate).add(1, 'days');
          if (plan.recurrence.recurrenceType === 'WEEK') {
            if (!moment(yesterdary).isSame(currentDate, 'week')) {
              if (e == every) {
                e = 1;
              } else {
                e++;
              }
            }
          }
        }
        quantity = q;

      } else if (plan.recurrence.recurrenceType === 'MONTH') {

        let yesterdary;
        let monthDay = (plan.recurrence.monthRecurrence || plan.recurrence.monthDay)

        while (!(moment(currentDate).isSame(endDatePlusOne, 'day'))) {
          if (e == every) {
            if (currentDate.date() == monthDay) {
              q++;
              if (makeEvent) this.makeEvent(events, currentDate, q);
            }
          }
          yesterdary = currentDate;
          currentDate = moment(currentDate).add(1, 'days');
          if (!moment(yesterdary).isSame(currentDate, 'month')) {
            if (e == every) {
              e = 1;
            } else {
              e++;
            }
          }
        }
        quantity = q;
      }
    }
    if (makeEvent) {
      return events
    }
    return quantity;
  }


  openAddService = () => {
    this.setState({ openService: true });
  }



  formatService2 = (item, appointment) => {

    var provider = this.state.provider;
    if (appointment) {
      provider = appointment.provider;
    }
    return {
      order: 1,
      type: 'Traslado',
      name: item.traslado.value,
      startDate: `${moment(item.startDate).format('DD/MM/YYYY')}`,
      quantity: this.getPlanQuantity(item, moment(new Date(item.startDate)), null, provider ? provider.dateEnd : null, false),
      actions: appointment && appointment.appointmentStatus === 'SCHEDULES_ASSIGNED' ? <div /> : this.renderActionComponent(item, appointment)
    }
  }

  formatService = (item, appointment) => {

    var provider = this.state.provider;
    if (appointment) {
      provider = appointment.provider;
    }
    var startDate = item.startDate
    var dateEnd = provider.dateEnd;

    if (appointment && (appointment.appointmentId == 100 || appointment.appointmentId == 101)) {
      var d = new Date();
      startDate = d.setDate(d.getDate() - 5);
      startDate = new Date(startDate);

      dateEnd = d.setDate(d.getDate() + 30);
      dateEnd = new Date(dateEnd);

    }
    return {
      order: 1,
      type: 'Traslado',
      name: item.traslado.value,
      startDate: moment(startDate).format('DD/MM/YYYY'),
      quantity: this.getPlanQuantity(item, moment(new Date(startDate)), null, provider ? dateEnd : null, false),
      actions: appointment && appointment.appointmentStatus === 'SCHEDULES_ASSIGNED' ? <div /> : this.renderActionComponent(item, appointment)
    }

  }




  renderActionComponent = (a, appointment) => {
    const { t } = this.props;

    if (appointment && appointment.appointmentStatus === 'CREATED') {

      return (
        <div className="appointments-actions">
          <Tooltip title={t('appointment.service.edit')}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => {
                  this.openAddService();
                }}
              >
                <Assignment />
              </Button>
            </div>
          </Tooltip>

        </div>
      )

    } else {

      return (
        <div className="appointments-actions">
          <Tooltip title={t('appointment.service.edit')}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => {
                  this.openAddService();
                }}
              >
                <Edit />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={t('appointment.service.delete')}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                authority="appointment_view,appointment_view_related"
                onClick={() => {
                  this.deleteService();
                }}
              >
                <Delete />
              </Button>
            </div>
          </Tooltip>
        </div>
      )

    }

  }

  closeService = () => {
    if (!this.state.appointment) {
      this.setState({
        openService: false,
        services: memory.tempService && memory.tempService.traslado ? [this.formatService(memory.tempService, this.state.appointment)] : []
      });
    }
  }

  deleteService = () => {
    memory.tempService = null;
    this.setState({
      services: []
    })
  }

  makeEvent = (events, date, index) => {

    const time = memory.tempService.startTime;
    date = new Date(date).setHours(parseInt(time.substring(0, 2)), parseInt(time.substring(3, 5)), 0);
    const label = memory.tempService.traslado.value;
    events.push(this.makeBooking(label, date, time, index))
  }

  makeBooking = (label, date, time, lastIndexOf) => {
    return {
      id: lastIndexOf,
      title: label,
      label: label,

      time_display: time,
      time_start: time,
      allDay: false,
      view: "month",
      start: date,
      end: date,
      color: "854FCB",
      employeeId: -1,
      status: "PENDING_APPROVAL",
      type: "booking",
      errorDetail: '',
    }
  }

  makeBookings = () => {

    if (!memory.tempService.startDate || !this.state.appointment.provider.dateEnd) {
      return [];
    }
    return this.getPlanQuantity(memory.tempService,
      moment(new Date(memory.tempService.startDate)),
      null, this.state.appointment.provider ? moment(new Date(this.state.appointment.provider.dateEnd)) : null, true)
  }

  makeBookingsFromAppointment = (appointment) => {


    return this.getPlanQuantity(memory.tempService,
      moment(memory.tempService.startDate),
      null, appointment.provider ? moment(appointment.provider.dateEnd) : null, true)
  }

  asignSchedules = () => {
    var bookings = this.makeBookings();
    memory.currentBookings = bookings;
    browserHistory.push({
      state: {
        appointment: this.state.appointment,
        bookings: bookings,
        dates: {
          startDate: this.state.dateStart,
          endDate: this.props.dateEnd
        }
      },
      pathname: '/turnos/' + this.props.params.id,
    });
  }

  saveAppointment = () => {

    if (!this.state.provider) {
      return;
    }
    //alert(JSON.stringify(this.state.customerId.customer.customerId));
    var body = {
      customer: this.state.customerId,
      startDate: this.state.provider.dateStart,
      endDate: this.state.provider.dateEnd,
      services: memory.tempService,
      identification: this.state.customerId.customer.customerId,
      curstomerObject: this.state.customerId,
      createdDateTime: new Date(),
      appointmentStatus: "CREATED",
      provider: this.state.provider,
      financier: this.state.financierData,
      driver: this.state.driver
    }
    var app = memory.appointments;
    body['appointmentId'] = 100 + app.length;
    app.push(body)
    memory.appointments = app;
    var startDate = moment(memory.tempService.startDate).format(
      'DD/MM/YYYY');
    this.sendSMS(
      body['appointmentId'],
      startDate,
      memory.tempService.nosocomio.value,
      memory.tempService.startTime,
      this.makeid(4),
      this.makeid(4)
    );

    //memory.tempService = null;


  }

  sendSMS = (idv, day, nosocomio, time, code_inicio, code_fin) => {
    var msg = 'Su viaje para el dia '.concat(day);
    msg = msg.concat(" con destino a ")
    msg = msg.concat(nosocomio)
    msg = msg.concat(" inicia ")
    msg = msg.concat(time)
    msg = msg.concat(" hs., codigo de inicio: ")
    msg = msg.concat(code_inicio)
    msg = msg.concat(", codigo de finalizacion: ")
    msg = msg.concat(code_fin)
    msg = msg.concat(".")

    var details = {
      'pUser': 'Hmmglobal',
      'pPassword': 'JxTGX1uU',
      'pToNum': 3512120314,
      'pToCompany': 0,
      'pFromNum': '11011',
      'pMessage': msg,
      'pmsgId': idv
    };

    const formData = new URLSearchParams();

    for (var property in details) {
      formData.append(property, details[property]);

      //var encodedKey = encodeURIComponent(property);
      //var encodedValue = encodeURIComponent(details[property]);
      //formBody.push(encodedKey + "=" + encodedValue);
    }

    fetch('/api/send_sms/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        body: formData.toString()
      })
    })
      .then((response) => response.json())

      .then((responseData) => {
      })
      browserHistory.push('/solicitudes');

  }

  makeid = (length) => {
    var result           = [];
    var characters       = '0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * 
      charactersLength)));
   }
   return result.join('');
}

  selectedEvent(event) {
    this.setState({ event: event, openService: true });
  }

  eventColors(event, start, end, isSelected) {
    let backgroundColor = 'event-'
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + 'default');
    return {
      className: backgroundColor,
    }
  }

  isInProgress = () => {
    if (this.props.params.id == 101) return true
    return false
  }

  handleChangeValue = () => {

  }

  /*
  <DialogTraslado
                  customer={this.state.appointment.customer}
                  appointment={this.state.appointment}
                  event={this.state.appointment.schedules[0]}
                  
                  viewMode={'apointment'}
                  provider={this.state.appointment.provider}
                  disabled={true}
                  saveService={this.saveService}
                  handleChangeValue={this.handleChangeValue}
                />*/

  render() {
    const { t } = this.props;
    return (
      <GridContainer>

        {this.state.appointment &&
          <GridItem className="appointment-data" xs={12}>

            <Card>
              <CardHeader icon>
                <CardIcon color="secondary">
                  <Assignment />
                </CardIcon>
                <h3 className="card-icon-title">{t('appointment.title')}</h3>
              </CardHeader>
              <CardBody>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem className="fullWidth-input validation-input" md={4} xs={4} sm={4}>
                    <CustomInput
                      id="appointment_id"
                      disabled={true}
                      labelText={t('appointment.input.appointment_id')}
                      value={this.state.appointment.appointmentId}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem className="fullWidth-input validation-input center-align" md={4} xs={4} sm={4}>

                    <CustomInput
                      id="appointment_status"
                      labelText={t('appointment.input.status')}
                      value={
                        this.state.appointment
                          ? t(`appointment.table.status.${this.state.appointment.appointmentStatus}`)
                          : null
                      }
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem className="align-right" md={4} xs={4} sm={4}>
                    <div className='align-right'>
                      <CustomInput
                        id="init_date"
                        disabled={true}
                        labelProps={{ style: { marginTop: '-10px' } }}
                        inputProps={{ style: { textAlign: 'right', width: '100%', marginLeft: '-20px' } }}

                        labelText={t('appointment.input.init_date')}
                        value={
                          this.state.appointment
                            ? moment(this.state.appointment.createdDateTime).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )
                            : null
                        }
                        formControlProps={{
                          disabled: true
                        }}
                      />
                    </div>

                  </GridItem>

                </GridContainer>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem className="fullWidth-input validation-input" xs={4} sm={4}>
                    <ValidationInput
                      id="appointment_id"
                      classes={{
                        input: "fullWidth-input"
                      }}
                      fullWidth
                      text={t('appointment.input.created_user')}
                      disabled={true}
                      className="fullWidth-input"
                      value={"-"}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem className="fullWidth-input validation-input center-align" xs={4} sm={4}>
                    <CustomInput
                      id="clientId"
                      labelText={t('appointment.input.clientId')}
                      value={'HMM'}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem className="fullWidth-input align-right" xs={4} sm={4}>
                    <CustomInput
                      id="foreignId"
                      labelText={t('appointment.input.foreignId')}
                      value={"-"}
                      labelProps={{ style: { textAlign: 'right', width: '100%' } }}
                      inputProps={{ style: { textAlign: 'right', width: '75%' } }}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        }
        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.CustomerFormService
            onFilledCustomer={(value) => { this.setState({ customerId: value, addressId: value.address, zoneId: value.zone }) }}
            value={this.state.appointment ? {
              "customer": this.state.appointment.customer.customer.id,
              "address": this.state.appointment.customer.address,
              "zone": this.state.appointment.customer.zone,
              "location": this.state.appointment.customer.location,
              "province": this.state.appointment.customer.province,
              "address_name": this.state.appointment.customer.address_name
            } : null}
            onUpdateCustomers={this.onUpdateCustomers}
            disabled={this.state.appointment != null}
          />
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.ProviderFormService
            customerId={this.state.customerId}
            onFilledProvider={value => this.setProviderF(value)}
            onFilledFinancier={value => this.setFinancier(value)}
            value={this.state.appointment ? {
              "provider": this.state.appointment.provider,
              "financier": this.state.appointment.financier

            } : null}
            disabled={this.state.appointment != null}
          />
        </GridItem>

        <GridItem className="schedule-form" xs={12} sm={12} md={12}>
          <Card>
            <CardHeader icon className="filters-header">
              <CardIcon color="secondary">
                <ViewList />
              </CardIcon>
              <h3 className="card-icon-title">{t('appointment.services')}</h3>
            </CardHeader>
            <CardBody className="appointments-content-body filtrable-table">
              <Table
                filterable
                loading={false}
                tableHeaderColor={"primary"}
                defaultPageSize={this.state.services.length > 10 ? 20 : 10}
                sortable
                tableHead={[
                  { Header: t('service.order'), accessor: 'order' },
                  { Header: t('service.type'), accessor: 'type' },
                  { Header: t('service.name'), accessor: 'name' },
                  { Header: t('service.start_date'), accessor: 'startDate', sortMethod: (a, b) => this.sortDate(a, b) },
                  { Header: t('service.quantity'), accessor: 'quantity', sortMethod: (a, b) => this.sortDate(a, b) },
                  { Header: t('appointment.table.actions'), accessor: 'actions', sortable: false, filterable: false },
                ]}
                tableData={this.state.services}
                colorsColls={['primary']}
                className="-striped -highlight filtrable sticky"
                showPaginationTop={false}
                showPaginationBottom={true}
              />

            </CardBody>
            <CardFooter className="schedule-form-submit buttonFooter">
              {!memory.tempService &&
                <Button className="buttonPlusFooter"
                  onClick={() => this.openAddService()}
                  disabled={!this.state.customerId ||
                    !this.state.customerId.latitude ||
                    !this.state.provider ||
                    !this.state.provider.dateStart ||
                    !this.state.provider.dateEnd}
                  color="primary"
                >
                  <Add />
                </Button>
              }
            </CardFooter>
          </Card>

          {this.isInProgress() && this.state.appointment &&

            <Card>
              <CardHeader icon className="filters-header">
                <CardIcon color="primary">
                  <CalendarToday />
                </CardIcon>
                <h3 className="card-icon-title">{t('appointment.trans.inprogress')}</h3>
              </CardHeader>
              <CardBody calendar>
                <DialogTraslado
                  customer={this.state.appointment.customer}
                  appointment={this.state.appointment}
                  event={this.state.appointment.schedules[0]}

                  viewMode={'apointment'}
                  provider={this.state.appointment.provider}
                  disabled={true}
                  saveService={this.saveService}
                  handleChangeValue={this.handleChangeValue}
                />
              </CardBody>
            </Card>
          }

          {this.state.appointment && this.state.appointment.schedules && this.state.appointment.appointmentStatus === 'SCHEDULES_ASSIGNED' &&

            <Card>
              <CardHeader icon className="filters-header">
                <CardIcon color="primary">
                  <CalendarToday />
                </CardIcon>
                <h3 className="card-icon-title">{t('appointment.agenda')}</h3>
              </CardHeader>
              <CardBody className="calendar-booking" calendar>
                <Calendar
                  selectable
                  showMultiDayTimes={true}
                  localizer={localizer}
                  events={this.state.appointment.schedules}
                  defaultView="month"
                  step={30}
                  views={['month']}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={event => this.selectedEvent(event)}
                  eventPropGetter={this.eventColors}
                  messages={{
                    next: t('common.next'),
                    previous: t('common.prev'),
                    today: t('common.today'),
                    month: t('common.month'),
                    week: t('common.week'),
                    day: t('common.day'),
                    event: t('common.event'),
                    date: t('common.date'),
                    time: t('common.hour'),
                  }}
                  components={{
                    event: EventWithIcon,
                  }}
                />
              </CardBody>
            </Card>
          }

          <GridContainer>
            <GridItem xs={10} sm={10} md={10}>
              <Button
                onClick={() => this.onBack()}
              >
                {t('appointment.cancel')}
              </Button>
            </GridItem>
            <GridItem xs={2} sm={2} md={2} style={{ textAlign: 'right' }}>
              {this.state.appointment != null && this.state.appointment.appointmentStatus === 'CREATED' &&
                <Button
                  color="blue"
                  onClick={() => this.asignSchedules()}
                  className='align-right'
                >
                  {t('appointment.asign')}
                </Button>

              }
              {this.state.appointment === null &&
                <Button
                  color="blue"
                  disabled={!memory.tempService || !memory.tempService.type}
                  onClick={() => this.saveAppointment()}
                >
                  {t('appointment.save')}
                </Button>
              }
            </GridItem>

          </GridContainer>
        </GridItem>
        <ModalDetailServiceTraslado
          open={this.state.openService}
          onCloseModal={this.closeService}
          disabled={this.state.appointment}
          event={this.state.event}
          viewMode={''}
          provider={this.state.provider}
          appointment={this.state.appointment}
          customer={this.state.appointment ? this.state.appointment.customer : this.state.customerId}
          onSaveService={this.addService}
        />
      </GridContainer>
    );
  }
}

AppointmentDetail.defaultProps = {
  selectedSchedules: [],
  onSaveSchedulesAppointment: () => { },
}

AppointmentDetail.propTypes = {
  selectedAppointment: PropTypes.object,
  providers: PropTypes.array,
  appointmentTools: PropTypes.array,
  selectedSchedules: PropTypes.array,
  onSelectAppointmentObject: PropTypes.func,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onGetAppointmentsTools: PropTypes.func,
  onDeleteAppointmentTool: PropTypes.func,
  onGetSchedulesAppointment: PropTypes.func,
  onSaveSchedulesAppointment: PropTypes.func
}

const styles = {
  cardHeader: {
    zIndex: '0 !important',
  }
};

export default withStyles(styles)(withTranslation()(AppointmentDetail));
