import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Checkbox } from '@material-ui/core';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table';
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import ButtonSpinner from '../../ButtonSpinner';
import './NewEmployeeType.css';

class NewEmployeeType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            EmployeeTypes: [],
            openEmployeeType: true,
            default: false,
            open: false,
            alertOpen: false,
            save: false,
            loading: false,
            checkedTypesList: [],
            busy: false,
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getTypes();
    }

    componentWillUnmount() {
        this.props.onUncheckAll();
    }
  
    getTypes(){
        PersonApiInvoker.getEmployeesType('PROFESSIONAL', data => {
            this.props.onGetEmployeeTypes(data);
            this.setState({ loading: false });
        }, () => {
            this.setState({ loading: false });
        });
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            openEmployeeType: false,
            component: null,
            current: null,
        });
    }

    saveEmployeeType() {
        if(this.props.checkType.length > 0){
            this.setState({
                loading: true,
                busy: true,
            });
            
            PersonApiInvoker.postEmployeeType(this.props.personId, this.props.checkType, data => {
                this.setState({
                    save: true,
                    busy: false,
                    loading: false,
                    open: false,
                });
                this.openAlert('success', this.props.t('employee.new.modal.success'));
                this.props.onPutEmployeeType(this.props.checkType);
                this.props.onUncheckAll();
                this.props.onTypeSubmited();
            }, () => {
                this.setState({ loading: false, busy: false });
            });
        } else {
            this.props.onTypeSubmited();
            this.setState({ loading: false, busy: false });
        }
    }

    openAlert(color, message, extraState) {
        this.setState(_.assign({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        }, extraState));

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 2000);
    }

    renderData = (selected, data) => {
        const selectedEmployee = selected.map((p) => p.employeeTypeId || p);

        return data.filter((d) => selectedEmployee.indexOf(d.employeeTypeId) < 0 ).map((a) => {
            const practiceTypeList = a.practiceType && a.practiceType.length ? a.practiceType.map((p) => p.name).join(', ') : '';
            const practiceTypeGroupList = a.practiceTypeGroup && a.practiceTypeGroup ? a.practiceTypeGroup.map((p) => p.name).join(', ') : '';
            return {
                ...a,
                input: (
                        <Checkbox
                            onChange={() => this.props.onCheckEmployeeType(a)}
                            checked={this.props.checkType.indexOf(a.employeeTypeId) !== -1}
                        />
                    ),
                employeeType: a.employeeTypeName,
                practiceTypeGroup: practiceTypeGroupList,
                praticeType: practiceTypeList,
            }
        });
    }

    render() {
        const { t, employee, employeesType } = this.props;
        const { loading } = this.state;
        const dataTable = this.renderData(employee.employeeTypes, employeesType);

        return (
            <GridContainer className="check-input NewEmployeeTypeModal">
                <GridItem xs={12} className="filtrable-table">
                    <Table
                        loading={loading}
                        tableHeaderColor="primary"
                        defaultPageSize={employeesType.length > 6 ? 20 : 5}
                        tableHead={[
                            { Header: '', accessor: 'input', sortable: false, width: 100 },
                            { Header: t('employees.practiceTypeGroup'), accessor: 'practiceTypeGroup', filterable: true, sortable: false },
                            { Header: t('employees.praticeType'), accessor: 'praticeType', filterable: true, sortable: false},
                        ]}
                        sortable={false}
                        tableData={dataTable}
                        colorsColls={["primary"]}
                        showPaginationBottom={true}
                    />
                </GridItem>
                <GridItem className="buttonFooterModal no-padding">
                    <ButtonSpinner
                        className="buttonPlusFooter"
                        onClick={() => this.saveEmployeeType()}
                        disabled={this.state.busy}
                        label={t('employee.new.accept')}
                        labelLoading={t('common.saving')}
                        loading={this.state.busy}
                        typeButton="submit"
                        color="success"
                    />
                </GridItem>
            </GridContainer>    
        )
    };
}

NewEmployeeType.defaultProps = {
    employeesType: [],
    checkType: [],
    onCheckEmployeeType: () => {},
    onUncheckAll: () => {},
    onPutEmployeeType: () => {},
}

NewEmployeeType.propTypes = {
    onUncheckAll: PropTypes.func,
    onPutEmployeeType: PropTypes.func,
    personId: PropTypes.number,
    onGetEmployeeTypes: PropTypes.func,
    onCheckEmployeeType: PropTypes.func,
    employeesType: PropTypes.array,
    checkType: PropTypes.array,
}

export default  withTranslation()(NewEmployeeType);
