import { connect } from 'react-redux'
import DataReport from '../components/DataReport'

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

const DataReportService = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataReport)

export default DataReportService
