import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import './CustomDialog.css';

class CustomDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        }
    }

    componentWillReceiveProps(next) {
        this.setState({
            open: next.open
        })
    }

    render() {
        return (
            <Dialog
                fullWidth
                maxWidth={this.props.maxWidth || 'md'}
                open={this.state.open}
            >
                <DialogTitle className={"dialog-title"}>
                    {this.props.title}
                    <Button
                        justIcon
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={this.props.onClose}
                    >
                        <Close />
                    </Button>
                </DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
            </Dialog>
        )
    }
}

CustomDialog.defaultProps = {
    onClose: () => {},
    maxWidth: 'md',
    open: false,
    title: "¿Esta Seguro?",
}

CustomDialog.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    maxWidth: PropTypes.string,
}

export default CustomDialog;