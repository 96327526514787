import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../Table/Table';
import Button from '../../CustomButtons/Button';
import { Checkbox } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import Confirm from '../../Confirm';
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import { withTranslation } from 'react-i18next';

class EmployeeGeographic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employeeGeographic: [],
            personId: '',
            loading: false,
            deleteAlert: false,
            success: false,
            error: false,
            selectedGeographic: '',
        }
    }

    componentWillReceiveProps(next) {        
        if(next.personId !== this.props.personId){
            PersonApiInvoker.getEmployeeGeographics(next.personId, (data) => {
                this.props.onGetEmployeeGeographics(data)
            });
        }
        
    }

    formatGeographic(a) {
        return {
            companyId: a.companyId,
            locationName: a.locationName,
            provinceName: a.provinceName,
            location: a.location && a.location.name,
            name: a.detail,
            actions: (this.props.componenMode === 'view') ? "" :<div>
                <Button simple justIcon color="danger" onClick={() => this.deleteEmployeeGeographic(a.geographicZoneId)}><Delete /></Button>
            </div>
        }            
    }

    deleteEmployeeGeographic(geographicId) {
        this.setState({
            deleteAlert: true,
            selectedGeographic: geographicId,
        })
    }

    deleteItemGeographic() {
        PersonApiInvoker.deleteEmployeeGeographic(this.props.personId, this.state.selectedGeographic, data => {
            const employeeGeographic = this.state.employeeGeographic
            employeeGeographic.map((a, i) => {
                if (a.geographicZoneId === this.state.selectedGeographic) {
                    employeeGeographic.shift(i)
                }
            })
            this.props.onDeleteItemGeographic(this.state.selectedGeographic)
            this.setState({
                success: true,
                employeeGeographic,
            })
        }, error => {
            this.setState({
                error: true,
            })
        })
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
        })
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: t('employee.new.province'), accessor: 'provinceName' },
            { Header: t('employee.new.location'), accessor: 'locationName' },
            { Header: t('employee.new.GeographicZone'), accessor: 'name' },
        ];
        componenMode !== 'view' && tableHead.push({ Header: t('employee.new.actions'), accessor: 'actions', sortable: false, width: 150 });
        return tableHead;
    }

    render() {
        const { t } = this.props;
        const { loading, deleteAlert, error, success } = this.state;
        const employeesGeographic = this.props.currentEmployeeGeographics && this.props.currentEmployeeGeographics.map(this.formatGeographic.bind(this));
        return (
            <>
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    sortable={false}
                    tableHead={this.renderTableHead()}
                    tableData={employeesGeographic}
                    colorsColls={["primary"]}
                >
                </Table>

                <Confirm
                    text={t('employee.new.modal.geographic')}
                    onClose={() => this.closeAlert()}
                    onConfirm={() => this.deleteItemGeographic()}
                    show={deleteAlert}
                    success={success}
                    error={error}
                />
            </>
        )
    }
}

EmployeeGeographic.defaultProps = {
    employeesGeographic: []
}

EmployeeGeographic.propTypes = {
    employees: PropTypes.array,
    employeesType: PropTypes.array,
    employeesGeographic: PropTypes.array,
    checkType: PropTypes.array,
    checkGeographic: PropTypes.array,
    employee: PropTypes.object,
    personId: PropTypes.string,
    onEmployeeGeographics: PropTypes.func,
    onDeleteItemGeographic: PropTypes.func,
    onGetEmployeeGeographics: PropTypes.func,
}

export default withTranslation()(EmployeeGeographic);