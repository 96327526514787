import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class ApiInvoker extends Component {

	static getSupplyCatalogHistory(scheduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule/${scheduleId}/supply-catalog-history`, callback, callerror);
	}

	static getCountries(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/countries`, callback, callerror);
	}

	static getProvinces(countryId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/countries/${countryId}/provinces`, callback, callerror);
	}

	static getLocations(provinceId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/provinces/${provinceId}/locations`, callback, callerror);
	}

	static getAddressTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/address-type`, callback, callerror);
	}

	static getGeographicZoneFromLocation(locationId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/geographic-zone/location/${locationId}`, callback, callerror);
	}

	static getTools(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tools-state`, callback, callerror);
	}

	static getToolType(toolTypeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tool-types/${toolTypeId}`, callback, callerror);
	}

	static postToolType(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/tool-types`, body, callback, callerror);
	}

	static postTool(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/tools`, body, callback, callerror);
	}

	// SupplyCatalog
	static getSupplyCatalog(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/supplyCatalogs`, callback, callerror);
	}

	static getSupplySuppl(supplyId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/supplyCatalog/${supplyId}`, callback, callerror);
	}

	static postSupplyCatalog(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/supplyCatalog`, body, callback, callerror);
	}


	// ToolTypes
	static getToolTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tool-types`, callback, callerror);
	}

	static getTool(toolId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tools/${toolId}`, callback, callerror);
	}

	static getAllProvinces(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/provinces`, callback, callerror);
	}



}