import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Assignment from '@material-ui/icons/Assignment';
import Add from '@material-ui/icons/Add';
import Copy from '@material-ui/icons/FileCopy';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import Table from '../Table/Table';
import CustomInput from '../CustomInput/CustomInput';
import SelectInput from '../SelectInput/index';
import Button from '../CustomButtons/Button';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import CustomDialog from '../CustomDialog/index';
import ButtonSpinner from '../ButtonSpinner';
import memory from '../../config/memory.js';

import { Calendar as CalendarIcon, HealthCalendar, DeleteDocument, EventDeclined } from '../../icons';
import './Appointments.css';


const dateFormat = 'DD/MM/YYYY';

class Appointments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appointments: [],
            loading: false,
            filterModules: '',
            filterProfessionals: '',
            filterStatus: '',
            filterTools: '',
            filterColors: [{ id: 1, value: this.props.t('appointment.table.filters.red') }, { id: 2, value: this.props.t('appointment.table.filters.yellow') }, { id: 3, value: this.props.t('appointment.table.filters.green') }, { id: 4, value: this.props.t('appointment.table.filters.rejected') }],
            dateFrom: '',
            dateTo: '',
            currentFilterColor: [],
            color: 'true',
            checkedInput: false,
            currentUserId: localStorage.getItem('user_id'),
            dialogCopyApointment: false,
            busy: false,
        }
    }

    componentWillMount() {
        this.getAppointments();
    }

    componentWillReceiveProps(next) {
        if (next.appointments) {
            this.reduceAppointmentes(next.appointments);
        }
    }

    getAppointments() {
        this.reduceAppointmentes(memory.appointments);
    }

    reduceAppointmentes(appointments) {
        if (appointments.length) {
            const data = appointments.map(this.appDataFormater);
            this.setState({ appointments: data });
        }
    }

    handleCustomer(e) {
        const value = e.target.value;
        const data = this.props.appointments && this.props.appointments
            .filter(a => {
                const name = `${a.customer.firstName} ${a.customer.lastName}`
                return name.toLowerCase().indexOf(value.toLowerCase()) !== -1
            })
            .map(this.appDataFormater);

        this.setState({ appointments: data });
    }

    handleAppointmentFilter(e) {
        const value = e.target.value.toString();
        const data = this.props.appointments && this.props.appointments
            .filter(a => a.appointmentId.toString().indexOf(value) !== -1)
            .map(this.appDataFormater);

        this.setState({ appointments: data });
    }

    handleFilters({ date, filter }) {
        const newState = {};
        let appointments = this.props.appointments || [];

        if (filter) {
            newState[(filter === 'to' ? 'dateTo' : 'dateFrom')] = date;
        }

        if (this.state.dateTo && !newState.dateTo) {
            newState.dateTo = this.state.dateTo;
        }

        if (this.state.dateFrom && !newState.dateFrom) {
            newState.dateFrom = this.state.dateFrom;
        }

        if (this.state.currentFilterColor.length) {
            appointments = appointments.filter(a => this.filterAppointmentOr(a));
        }
        if (newState.checkedInput || newState.dateFrom || newState.dateTo) {
            appointments = appointments.filter(a => this.filterAppointmentAnd(a, newState))
        }

        appointments = appointments.map(this.appDataFormater);

        newState.appointments = appointments;

        this.setState(newState);
    }


    filterAppointmentAnd(a, newState) {
        const userId = parseInt(this.state.currentUserId);
        let accepted = true;
        let personIdentifier = a.responsableEmployee && a.responsableEmployee.personId;
        const appointmentDateMoment = moment(a.createdDateTime);

        if (newState.checkedInput) {
            accepted = accepted && personIdentifier === userId;
        }
        if (newState.dateFrom) {
            accepted = accepted && appointmentDateMoment.isSameOrAfter(newState.dateFrom, 'days');
        }
        if (newState.dateTo) {
            accepted = accepted && appointmentDateMoment.isSameOrBefore(newState.dateTo, 'days');
        }

        return accepted;
    }

    filterAppointmentOr(a) {
        let now = moment();
        let endDateO = moment(a.endDate);
        let daysLeft = Math.ceil(endDateO.diff(now, 'days', true));
        let accepted = false;

        if (this.state.currentFilterColor.indexOf(1) > -1) {
            // Filter Red Icons
            accepted = accepted || (daysLeft >= 0 && daysLeft <= 5);
        }
        if (this.state.currentFilterColor.indexOf(2) > -1) {
            // Filter 
            accepted = accepted || (daysLeft >= 6 && daysLeft <= 10);
        }
        if (this.state.currentFilterColor.indexOf(3) > -1) {
            // Filter Rejected 
            accepted = accepted || (daysLeft >= 11 && daysLeft <= 15);
        }
        if (this.state.currentFilterColor.indexOf(4) > -1) {
            // Filter Rejecteds Icons
            accepted = accepted || a.hasSchedulesRejected;
        }
        return accepted;
    }

    appDataFormater = (a) => {
        var d = new Date();
        if (a.startDate == "") {
            a.startDate = d.setDate(d.getDate() - 5);
            a.endDate = d.setDate(d.getDate() + 30);
        }
        return {
            appointmentId: a.appointmentId,
            date: moment(a.createdDateTime).format(dateFormat),
            name: (a.customer.customer ? a.customer.customer.value : a.customer.name),
            customerId: a.customer.id,
            identification: a.identification,
            startDate: moment(a.startDate).format(dateFormat),
            endDate: moment(a.endDate).format(dateFormat),
            appStatus: this.props.t(`appointment.table.status.${a.appointmentStatus}`) || this.props.t('appointment.table.status.DEFAULT'),
            actions: this.renderActionComponent(a),
        }
    }

    endDateFormater(endDate, hasSchedulesRejected) {
        const { t } = this.props;
        const now = moment();
        const endDateF = moment(endDate);
        const daysLeft = Math.ceil(endDateF.diff(now, 'days', true));
        let rejected = '';
        if (hasSchedulesRejected) {
            rejected = <Tooltip className="toolTipEdit" title={`${t('appointment.table.rejected')}`}>
                <div>
                    <Button
                        disabled
                        className="noButton"
                        simple
                        justIcon
                        color="danger"
                    >
                        <EventDeclined className='svgdanger svgIcon' />
                    </Button>
                </div>
            </Tooltip>
        }
        if (daysLeft >= 11 && daysLeft <= 15) {
            return (
                <div>
                    <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${t('appointment.table.days')} `}>
                        <div>
                            <Button
                                disabled
                                className="noButton"
                                simple
                                justIcon
                                color="success"
                            >
                                <CalendarIcon className="svgsuccess svgIcon" fontSize="large" />
                            </Button>
                        </div>
                    </Tooltip>
                    {rejected}
                </div>
            )
        } else if (daysLeft >= 6 && daysLeft <= 10) {
            return (
                <div>
                    <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${t('appointment.table.days')} `}>
                        <div>
                            <Button
                                disabled
                                className="noButton"
                                simple
                                justIcon
                                color="secondary"
                            >
                                <CalendarIcon className="svgyellow svgIcon" />
                            </Button>
                        </div>
                    </Tooltip>
                    {rejected}
                </div>
            )
        } else if (daysLeft <= 5 && daysLeft > 0) {
            return (
                <div>
                    <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining')} ${daysLeft} ${(daysLeft === 1) ? t('appointment.table.day') : t('appointment.table.days')} `}>
                        <div>
                            <Button
                                disabled
                                className="noButton"
                                simple
                                justIcon
                                color="danger"
                            >
                                <CalendarIcon className='svgdanger svgIcon' />
                            </Button>
                        </div>
                    </Tooltip>
                    {rejected}
                </div>
            )
        } else if (daysLeft === 0) {
            return (
                <div>
                    <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining.today')} `}>
                        <div>
                            <Button
                                disabled
                                className="noButton"
                                simple
                                justIcon
                                color="danger"
                            >
                                <CalendarIcon className='svgdanger svgIcon' />
                            </Button>
                        </div>
                    </Tooltip>
                    {rejected}
                </div>
            )
        } else if (daysLeft < 0) {
            return (
                <div>
                    <Tooltip className="toolTipEdit" title={`${t('appointment.table.remaining.end')} `}>
                        <div>
                            <Button
                                disabled
                                className="noButton"
                                simple
                                justIcon
                            >
                                <CalendarIcon className='svgdefault svgIcon' />
                            </Button>
                        </div>
                    </Tooltip>
                    {rejected}
                </div>
            )
        } else {
            return (
                <div>
                    {rejected}
                </div>
            )
        }
    }

    renderActionComponent = (a) => {
        const { t } = this.props;
        var label = (a.appointmentStatus === 'CREATED' ? t('appointment.asignSchedule') : t('appointment.viewAppointment'));
        return (
            <div className="appointments-actions">
                <Tooltip title={label}>
                    <div>
                        <Button
                            simple
                            justIcon
                            color="info"
                            authority="appointment_view,appointment_view_related"
                            onClick={() => {
                                    this.props.onSelectAppointmentList(a.appointmentId);
                                    browserHistory.push({
                                        state: { mode: 'view' },
                                        pathname: `/solicitudes/${a.appointmentId}`
                                    });
    
                            }}
                        >
                            <Assignment />
                        </Button>
                    </div>
                </Tooltip>
                
            </div>
        )
    }

    handleValue(value, state) {
        this.setState({
            [state]: value,
        }, () => {
            this.handleFilters({ date: '', filter: value });
        });
    }

    addAppointment() {
        browserHistory.push('/new-appointment');
    }

    handleStatus(e) {
        const status = e.target.value;
        const data = this.props.appointments
            .filter(app => {
                const currentStatus = _.get(app, 'appointmentStatus', '')
                return currentStatus.toLowerCase().indexOf(status.toLowerCase()) !== -1
            })
            .map(this.appDataFormater.bind(this));

        this.setState({ appointments: data });
    }

    sortDate = (a, b) => {
        const a1 = moment(a, dateFormat).valueOf();
        const b1 = moment(b, dateFormat).valueOf();
        if (a1 < b1)
            return -1;
        else if (a1 > b1)
            return 1;
        else
            return 0;
    }

    toggleCopy = () => {
        this.setState({
            dialogCopyApointment: !this.state.dialogCopyApointment,
            busy: false,
        });
    }

    checkAppointment = () => {
        this.setState({ busy: true });
        AppointmentApiInvoker.getAppointment(
            this.state.appointment_copy,
            (data) => {
                //mlg
                this.props.onSelectAppointmentList(-1);
                this.props.onSelectAppointmentObject(null);
                browserHistory.push({
                    state: { mode: "copy" },
                    pathname: `/appointments/${this.state.appointment_copy}/renew/agenda`
                });
            }, (err) => {
                this.setState({
                    dialogCopyApointment: !this.state.dialogCopyApointment,
                    alertWarningCopy: true,
                    busy: false,
                });
            }
        );
    }

    render() {
        const { t } = this.props;
        const { alertWarningCopy, appointments, loading } = this.state;
        return (
            <GridContainer className="appointments appointments-list">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader icon className="filters-header">
                            <CardIcon color="secondary">
                                <Assignment />
                            </CardIcon>
                            <GridContainer justify="flex-end">
                                <GridItem className="filter-appointments date-size appointment-header">
                                    <Tooltip className="toolTipEdit" title={`${t('appointment.new.newappointment')} `}>
                                        <div>
                                            <Button
                                                className="add-content-button appointments-button"
                                                round
                                                color="primary"
                                                authority="appointment_new"
                                                onClick={() => this.addAppointment()}
                                            >
                                                <Add className="appointments-button-icon" /> {t('appointment.create')}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                    
                                </GridItem>
                            </GridContainer>
                            
                        </CardHeader>
                        <CardBody className="appointments-content-body filtrable-table">
                            <Table
                                filterable
                                loading={loading}
                                tableHeaderColor={"primary"}
                                defaultPageSize={appointments.length > 10 ? 20 : 10}
                                sortable
                                tableHead={[
                                    { Header: t('appointment.table.appointment_id'), accessor: 'appointmentId' },
                                    { Header: t('appointment.table.init_date'), accessor: 'date', sortMethod: (a, b) => this.sortDate(a, b) },
                                    { Header: t('appointment.table.identification'), accessor: 'identification' },
                                    { Header: t('appointment.table.customer.name'), accessor: 'name' },
                                    { Header: t('appointment.table.start_date'), accessor: 'startDate', sortMethod: (a, b) => this.sortDate(a, b) },
                                    { Header: t('appointment.table.end_date'), accessor: 'endDate', sortMethod: (a, b) => this.sortDate(a, b) },
                                    { Header: t('appointment.table.status'), accessor: 'appStatus' },
                                    { Header: t('appointment.table.actions'), accessor: 'actions', sortable: false, filterable: false },
                                ]}
                                tableData={this.state.appointments}
                                colorsColls={['primary']}
                                className="-striped -highlight filtrable sticky"
                                showPaginationTop={false}
                                showPaginationBottom={true}
                            />
                        </CardBody>
                    </Card>
                </GridItem>

                <CustomDialog
                    title={t('appointment.renew.title')}
                    maxWidth={'sm'}
                    open={this.state.dialogCopyApointment}
                    onClose={() => this.toggleCopy()}
                >
                    <div>
                        <p className="appointmentRenewTitle">
                            {t('appointment.renew.question')}
                        </p>
                        <div className="booking-errors-actions">
                            <CustomInput
                                className="filter-number"
                                id="appointment_copy"
                                type="number"
                                formControlProps={{
                                    fullWidth: false,
                                }}
                                inputProps={{
                                    autocomplete: "no"
                                }}
                                onChange={e => this.setState({ appointment_copy: e.target.value }) }
                            />
                        </div>
                    </div>
                    <div className="content-actions">
                        <ButtonSpinner
                            onClick={() => this.checkAppointment()}
                            disabled={this.state.busy}
                            label={t('appointment.renew.acept')}
                            labelLoading={t('common.saving')}
                            loading={this.state.busy}
                            typeButton="submit"
                            color="primary"
                        />
                    </div>
                </CustomDialog>

                {alertWarningCopy &&
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        onConfirm={() => this.setState({ dialogCopyApointment: true, alertWarningCopy: false })}
                        confirmBtnCssClass={"btn success"}
                        confirmBtnText={t('appointment.new.accept')}
                        showCancel={false}
                    >
                        <p>
                            {t('appointment.renew.renew_warning')}
                        </p>
                    </SweetAlert>
                }

            </GridContainer>
        )
    }
}

Appointments.defaultProps = {
    appointments: []
}

Appointments.propTypes = {
    onGetAppointments: PropTypes.func.isRequired,
    onDeleteAppointment: PropTypes.func.isRequired,
    onSelectAppointment: PropTypes.func.isRequired,
    onSelectAppointmentList: PropTypes.func.isRequired,
    onSelectAppointmentObject: PropTypes.func.isRequired,
    appointments: PropTypes.any
}

export default withTranslation()(Appointments);
