import { Module } from 'module';
import Routes from './components/Routes';
import { Icon } from '@material-ui/core';

import Appointments from './components/Appointments';
import AppointmentDetail from './components/Appointments/AppointmentDetail';

import Customers from './components/Customers';
import NewCustomer from './components/NewCustomer/new-customer';
import EditCustomer from './components/EditCustomer/edit-customer';


import Employees from './components/Employees';
import NewEmployee from './components/NewEmployee/new-employee';
import EditEmployee from './components/EditEmployee/EditEmployee';



// icons
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Car from './icons/Drivers'

import { ItineraryOutlined, MedicalDoctorOutlined, SyringeOutlined } from '../src/icons';
import DataReport from './components/EditEmployee/EditEmployee';

const routes = [

  {
    path: '/solicitudes',
    name: 'routes.appointments',
    mini: 'routes.appointments.mini',
    rtlName: '',
    component: Appointments,
    layout: '',
    enabled: true,
    authority: 'menu_appointment'
  },
  {
    path: '/appointments/:id/edit/agenda',
    name: 'routes.appointments',
    mini: 'routes.appointments.mini',
    rtlName: '',
    component: AppointmentDetail,
    layout: '',
    enabled: true,
    hidden: true
  },


  {
    path: '/nuevo-paciente',
    name: 'routes.newPatient',
    mini: 'routes.newPatient.mini',
    rtlName: '',
    component: NewCustomer,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/editar-paciente/:id',
    name: 'routes.editPatient',
    mini: 'routes.editPatient.mini',
    rtlName: '',
    component: EditCustomer,
    layout: '',
    enabled: true,
    hidden: true,
    authority: 'customers_edit'
  },
  {
    path: '/ver-paciente/:id',
    name: 'routes.viewPatient',
    mini: 'routes.viewPatient.mini',
    inset: 10,
    rtlName: '',
    component: EditCustomer,
    layout: '',
    enabled: true,
    hidden: true,
    authority: 'customers_view,customers_view_related'
  },
  
  {
    path: '/profesionales',
    name: 'routes.drivers',
    mini: 'routes.professionals.mini',
    icon: Car,

    rtlName: '',
    component: Employees,
    layout: '',
    enabled: true,
    authority: 'menu_employees'
  },
  {
    path: '/patient',
    name: 'routes.patients',
    mini: 'routes.patients.mini',
    icon: HotelOutlinedIcon,
    rtlName: '',
    component: Customers,
    layout: '',
    enabled: true,
    authority: 'menu_customers',
    views: [

    ],
  },

  {
    path: '/nuevo-paciente',
    name: 'routes.newPatient',
    mini: 'routes.newPatient.mini',
    rtlName: '',
    component: NewCustomer,
    layout: '',
    enabled: true,
    hidden: true
  },
  
  {
    path: '/nuevo-profesional',
    name: 'routes.newProfessional',
    mini: 'routes.newProfessional.mini',
    rtlName: '',
    component: NewEmployee,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/editar-profesional/:id',
    name: 'routes.editProfessional',
    mini: 'routes.editProfessional.mini',
    rtlName: '',
    component: EditEmployee,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/ver-profesional',
    name: 'routes.viewProfessional',
    mini: 'routes.viewProfessional.mini',
    rtlName: '',
    component: EditEmployee,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/modulos',
    name: 'Package',
    mini: 'routes.package.mini',
    rtlName: '',
    component: Module,
    layout: '',
    enabled: true,
    authority: 'menu_package'
  },

  {
    collapse: true,
    name: 'routes.settings',
    rtlName: '',
    mini: 'CO',
    icon: SettingsOutlinedIcon,
    state: 'mantenimientoCollapse',
    authority: 'menu_maintenance',
    views: [],
  },


  {
    path: '/statistics',
    name: 'routes.statistics',
    mini: 'routes.statistics.mini',
    icon: PollOutlinedIcon,
    rtlName: '',
    component: DataReport,
    layout: '',
    enabled: true,
    views: [

    ],
  },


  
]

export default routes;
