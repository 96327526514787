import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Edit, Delete } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import Confirm from '../Confirm';
import Table from '../Table/Table';
import PersonApiInvoker from "../../api/PersonApiInvoker";

class Phone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phones: [],
            loading: true,
            deleteAlert: false,
            selectedPhone: '',
            success: false,
            error: false,
        }
    }

    componentWillReceiveProps(next) {
        if (next.personId && next.refresh) {
            this.findPhones(next.personId);
        }
    }

    findPhones(personId) {
        PersonApiInvoker.getPersonPhone(personId, phones => {
            const p = this.formatPhone(phones)
            this.setState({
                phones: p,
                loading: false,
            })
        }, null)
    }

    formatPhone(phones) {
        let result = []
        phones.map(p => {
            result.push({
                phoneId: p.phoneId,
                detail: p.detail,
                type: p.phoneType.name,
                number: p.number,
                actions: (this.props.componenMode !== 'view') && <div><Button simple justIcon color="primary" onClick={() => this.editPhone(p)}><Edit /></Button>
                    <Button simple justIcon color="danger" onClick={() => this.deletePhone(p.phoneId)}><Delete /></Button></div>
            })
        })
        return result;
    }

    editPhone(phone) {
        this.props.onEditPhone(phone)
    }

    deletePhone(phoneId) {
        this.setState({
            deleteAlert: true,
            selectedPhone: phoneId,
        })
    }

    deleteItem() {
        PersonApiInvoker.deletePersonsPhone(this.props.personId, this.state.selectedPhone, data => {
            const phones = this.state.phones
            phones.map((p, i) => {
                if (p.phoneId === this.state.selectedPhone) {
                    phones.shift(i)
                }
            })
            this.setState({
                success: true,
                phones,
            })
        }, error => {
            this.setState({
                error: true,
            })
        })
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
        });
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: t('common.description'), accessor: 'detail' },
            { Header: t('tooltypes.table.toolType'), accessor: 'type' },
            { Header: t('employee.new.modal.phone'), accessor: 'number' },
        ];
        componenMode !== 'view' && tableHead.push({ Header: t('employee.new.actions'), accessor: 'actions', sortable: false, width: 150 });
        return tableHead;
    }

    render() {
        const { t } = this.props;
        const { loading, deleteAlert, error, phones, success } = this.state;
        return (
            <>
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={10}
                    sortable={true}
                    tableHead={this.renderTableHead()}
                    tableData={phones}
                    colorsColls={["primary"]}>
                </Table>
                <Confirm
                    text={t('employee.new.modal.phone')}
                    onClose={() => this.closeAlert()}
                    onSuccess={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    show={deleteAlert}
                    success={success}
                    error={error}
                />
            </>
        )
    }
}

Phone.propTypes = {
    personId: PropTypes.any,
    onEditPhone: PropTypes.func,
}

export default withTranslation()(Phone);