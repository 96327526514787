import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import Button from '../CustomButtons/Button';
import CustomDialog from '../CustomDialog';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Snackbar from '../Snackbar/Snackbar';
import EventWithIcon from '../EventWithIcon/EventWithIcon';
import BookingDetail from './BookingDetail';
import SchedulerInvoker from '../../api/SchedulerInvoker';
import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import './Bookings.css';
import BeforeUnloadComponent from 'react-beforeunload-component';
import memory from '../../config/memory';

require('moment/locale/es.js');

const localizer = momentLocalizer(moment)

class Bookings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      events: [],
      alert: null,
      openDetail: false,
      updateErrors: [],
      alertServerOpen: false,
      alertErrorOpen: false,
      alertStatus: '',
      alertMessage: '',
      alertColor: '',
      calendarViewMode: 'month',
      dates: {},
      busy: false,
    }
    this.componentCleanup = this.componentCleanup.bind(this);
  }


 componentCleanup() {
    this.cancel()
  }

  onUnload = (event) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload, false);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload, false)
    if (this.props.bookings && !(this.props.location.state && this.props.location.state.fromAppointmentAssignAgenda)) {
      this.formatBookings(this.props.bookings)
    } else {
      this.props.onGetBookings(this.props.location.state.bookings);
      this.formatBookings(this.props.location.state.bookings)
    }
    this.getAppointment(this.props.params.id);
  }

  componentWillMount() {
    window.addEventListener('unload', this.componentCleanup);
  }

  getAppointment = (appointmentId) => {
    var appointment = memory.appointments.filter(item => item.appointmentId == appointmentId);
    if (appointment.length > 0) {
      const dates = {startDate: appointment[0].provider.dateStart, endDate: appointment[0].provider.dateEnd}
      this.setState({dates: dates});
    }
  }

  formatBookings(data) {
    let bookings = memory.currentBookings;
    bookings = _.orderBy(bookings, ['scheduleDateTime'], ['asc'])
    let result = []
    bookings.map(b => {
      if (b) {
        result.push({
          id: b.scheduleBookingId || b.scheduleId,
          /* title: `${moment(b.scheduleDateTime).format('HH:mm')} - ${b.label}`, */
          title: b.label,
          time_display: "",
          time_start: moment(b.start).format('HH:mm'),
          allDay: false,
          view: this.state.calendarViewMode,
          start: b.start,
          end: b.end,
          color: this.getColor(1),
          employeeId: b.employeeId,
          status: b.status,
          type: b.type,
          errorDetail:''
        })
      }
    });
    this.setState({ events: result });
  }

  getColor(group) {
    //TODO: HMM-749
    const colors = {
      0: 'E157CA',
      1: '854FCB',
      2: '5AB4FF',
      3: '79009B',
      4: 'EA5757',
      5: '0A85FF',
      6: 'BF00B0',
      7: '880E4F',
      8: '1F3FB9',
    };

    return colors[group % 9];
  }

  selectedEvent(event) {
    
  }

  cancel() {
    browserHistory.push({
      state: { mode: 'assign_agenda' },
      pathname: `/solicitudes/${this.props.params.id}`
    })
    this.setState({ busy: false });
  }

  confirmBookigs() {
    this.setState({
      alertOpen: true,
      alertStatus: "OK",
    });
    return;
  }

  openAlert = (color, message) => {
    this.setState({ alertColor: color, alertMessage: message, alertServerOpen: true });

    setTimeout(() => {
      this.setState({
        alertServerOpen: false,
        alertOpen: false,
      });
    }, 2000);

  }

  saveBookings() {
    this.setState({ busy: true });
    var appointments = memory.appointments;
    var newappointments = [];
    appointments.forEach((b, i) => {
      if (b.appointmentId == this.props.params.id) {
        b['schedules'] = memory.currentBookings;
        b['appointmentStatus'] = "SCHEDULES_ASSIGNED";
        console.log(JSON.stringify(b));
      }
      newappointments.push(b);
      
    });  
    memory.appointments = newappointments; 
    browserHistory.push('/solicitudes');
  }

  eventColors(event, start, end, isSelected) {
    let backgroundColor = 'event-'
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + 'default');
    return {
      className: backgroundColor,
    }
  }

  onChangeView = (event) => {
    this.state.events.forEach((b, i) => {
      b.view = event
    });
    this.setState({ calendarViewMode: event });
  }

  render() {
    const { t, scheduleDates } = this.props;
    const mThis = this;
    return (
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Card>
              {(scheduleDates || this.state.dates) && (
                <CardHeader className="text-center">
                  <h4 className="card-title">
                    <strong>{t('appointment.calendar.dates')}</strong>
                    {t('appointment.calendar.fromToDates',
                      {
                        dateFrom: moment(scheduleDates ? scheduleDates.startDate : this.state.dates.startDate).format(
                          'DD-MM-YYYY'
                        ), dateTo: moment(scheduleDates ? scheduleDates.endDate : this.state.dates.endDate).format(
                          'DD-MM-YYYY'
                        )
                      })}
                  </h4>
                </CardHeader>
              )}
              <CardBody className="calendar-booking" calendar>
                <Calendar
                  selectable
                  showMultiDayTimes={true}
                  localizer={localizer}
                  events={this.state.events}
                  defaultView="month"
                  step={30}
                  views={['month']}
                  onView={event => this.onChangeView(event)}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={event => this.selectedEvent(event)}
                  eventPropGetter={this.eventColors}
                  messages={{
                    next: t('common.next'),
                    previous: t('common.prev'),
                    today: t('common.today'),
                    month: t('common.month'),
                    week: t('common.week'),
                    day: t('common.day'),
                    event: t('common.event'),
                    date: t('common.date'),
                    time: t('common.hour'),
                  }}
                  components={{
                    event: EventWithIcon,
                  }}
                />
                <CustomDialog
                  title={t('appointment.calendar.scheduleDialogTitle') + (this.state.currentEvent && this.state.currentEvent.type !== 'booking' ? t('appointment.calendar.scheduleDialogTitle.next') : '')}
                  maxWidth={'sm'}
                  open={this.state.openDetail}
                  onClose={() => this.setState({ openDetail: false })}
                >
                  <BookingDetail
                    readOnly={!(this.state.currentEvent && this.state.currentEvent.color)}
                    event={this.state.currentEvent}
                    onCancel={() => this.setState({ openDetail: false })}
                    onUpdate={booking => this.updateBooking(booking)}
                    errors={this.state.updateErrors}
                    status={this.state.status}
                  />
                </CustomDialog>
                <CustomDialog
                  maxWidth={'sm'}
                  open={this.state.alertOpen}
                  onClose={() => this.setState({ alertOpen: false })}
                  title={t('appointment.new.appointmentReview')}
                >
                  <div className="booking-errors">
                    <div className="booking-errors-list">
                      <span className="schedule-errors-item">
                        {' '}
                        <Icon
                          color={
                            this.state.alertStatus === 'ERROR'
                              ? 'error'
                              : 'disabled'
                          }
                        >
                          {this.state.alertStatus === 'WARNING' ? 'warning' : this.state.alertStatus === 'ERROR' ? 'error' : 'disabled'}
                        </Icon>{' '}
                        {(this.state.alertStatus === 'WARNING') || this.state.alertStatus === 'ERROR' ? (
                          <div className="schedule-errors-list">
                            {this.props.bookings.errors && this.props.bookings.errors.length > 0 &&
                              this.props.bookings.errors.map(e => {
                                return (
                                  <span className="schedule-errors-item">
                                    {e.detail}
                                  </span>
                                )
                              })}
                          </div>
                        ) : (
                            <span>
                              {t('appointment.calendar.confirm')}
                            </span>
                          )}
                      </span>
                    </div>
                    <div className="booking-errors-actions">
                      <Button
                        color="danger"
                        onClick={() => this.setState({ alertOpen: false })}
                      >
                        {t('common.cancel')}
                      </Button>
                      <ButtonSpinner
                        className="buttonSpinner"
                        loading={this.state.busy}
                        color="success"
                        onClick={() => this.saveBookings()}
                        disabled={this.state.alertStatus === 'ERROR' || this.state.busy}
                        label={t('common.save')}
                        labelLoading={t('common.saving')}
                      />
                    </div>
                  </div>
                </CustomDialog>

                <CustomDialog
                  maxWidth={'sm'}
                  open={this.state.alertErrorOpen}
                  onClose={() => this.setState({ alertErrorOpen: false })}
                  title={t('appointment.new.appointmentReview')}
                >
                  <div className="booking-errors">
                    <div className="booking-errors-list">
                      <span className="schedule-errors-item">
                        {' '}
                        <Icon color={'error'}>error</Icon>{' '}
                        <span>{this.state.alertErrorMessage}</span>
                      </span>
                    </div>
                    <div className="booking-errors-actions">
                      <Button
                        color="danger"
                        onClick={() => this.setState({ alertErrorOpen: false })}
                      >
                        {t('common.cancel')}
                      </Button>
                    </div>
                  </div>
                </CustomDialog>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} justify={'space-between'} className="footerButtons">
            <Button color="default" onClick={() => this.cancel()}>
              {t('appointment.new.backStep')}
            </Button>
            <Button color="success" onClick={() => this.confirmBookigs()}>
              {t('appointment.new.accept')}
            </Button>
          </GridItem>
          <Snackbar
            place="tr"
            color={this.state.alertColor}
            message={this.state.alertMessage}
            open={this.state.alertServerOpen}
          />
        </GridContainer>
    )
  }
}

Bookings.propTypes = {
  bookings: PropTypes.object,
  onSaveBookings: PropTypes.func,
  addSchedule: PropTypes.func,
  scheduleDates: PropTypes.object.isRequired,
};

export default withTranslation()(Bookings);
