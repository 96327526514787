import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import Confirm from '../Confirm';
import Button from '../CustomButtons/Button';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import PersonApiInvoker from "../../api/PersonApiInvoker";

class Address extends Component {
    constructor(props) {
        super(props);

        this.state = {
            personAddress: [],
            personId: '',
            loading: true,
            deleteAlert: false,
            success: false,
            error: false,
            selectedAddres: '',
        }
    }

    componentWillReceiveProps(next) {        
        if (next.personId && next.refresh) {
            PersonApiInvoker.getPersonsAddress(next.personId, false, (data) => {
                const personAddress = this.formatAddress(data);
                this.setState({
                    personAddress,
                    loading: false,
                });
            })
        }
    }

    formatAddress(address) {
        const result = address.map(a => {
            return {
                addressId: a.addressId,
                addressType: a.addressType.name,
                street: a.street,
                number: a.number,
                location: (a.location && a.location.name) && a.location.name,
                province: a.provinceName,
                default: <Checkbox checked={a.addressDefault} />,
                actions: this.props.componenMode !== 'view' &&
                    <>
                        <Button simple justIcon color="success" onClick={() => this.editAddress(a)}><Edit /></Button>
                        <Button simple justIcon color="danger" onClick={() => this.deleteAddress(a.addressId)}><Delete /></Button>
                    </>
            }
        })
        return result;
    }

    editAddress(address) {
        this.props.onEditAddress(address);
    }

    deleteAddress(addressId) {
        this.setState({
            deleteAlert: true,
            selectedAddres: addressId,
        });
    }

    deleteItem() {
        PersonApiInvoker.deletePersonsAddress(this.props.personId, this.state.selectedAddres, data => {
            let address = this.state.personAddress;
            address = address.filter(addr => addr.addressId !== this.state.selectedAddres);
            this.setState({
                success: true,
                personAddress: address,
            })
        }, err => {
            console.error(err);

            if(err.message){
                this.openAlert('danger', err.message);
                this.closeAlert();
            } 
            else {
                this.setState({
                    error: true,
                    success: false,
                });
            }
        })
    }

    closeAlert() {
        this.setState({
            deleteAlert: false,
            success: false,
            error: false,
        });
    }
    
    openAlert = (color, message) => {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 2000);
    }

    renderTableHead = () => {
        const { t, componenMode } = this.props;
        const tableHead =  [
            { Header: t('address.type'), accessor: 'addressType' },
            { Header: t('address.street'), accessor: 'street' },
            { Header: t('address.number'), accessor: 'number' },
            { Header: t('address.location'), accessor: 'location' },
            { Header: t('address.province'), accessor: 'province' },
            { Header: t('address.mainAddress'), accessor: 'default' },
        ];
        componenMode !== 'view' && tableHead.push({ Header: t('common.actions'), accessor: 'actions', sortable: false, width: 150 });
        return tableHead;
    }

    render() {
        const { alertColor, alertMessage, alertOpen, deleteAlert, error, loading, personAddress, success } = this.state;
        const { t } = this.props;

        return (
            <div className="address-list">
                <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={10}
                    sortable={true}
                    tableHead={this.renderTableHead()}
                    tableData={personAddress}
                    colorsColls={["primary"]}
                />

                <Snackbar
                    place="tr"
                    color={alertColor}
                    message={alertMessage}
                    open={alertOpen}
                />

                <Confirm
                    text={t('address.title')}
                    onClose={() => this.closeAlert()}
                    onConfirm={() => this.deleteItem()}
                    show={deleteAlert}
                    success={success}
                    onSuccess={() => this.closeAlert()}
                    error={error}
                />
            </div>
        )
    }
}

Address.propTypes = {
    personId: PropTypes.any,
    onEditAddress: PropTypes.func,
    componenMode: PropTypes.string.isRequired,
}

export default withTranslation()(Address);
