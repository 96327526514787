import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import Button from '../CustomButtons/Button';

const ButtonSpinner = (props) => {
  const {
    block, className, disabled, fullWidth, typeButton, onClick, color, loading, label, labelLoading,
  } = props;
  const useStyles = makeStyles(() => ({
    buttonSpinner: {
      margin: '5px 0',
      '& svg': {
        margin: 0,
      },
    },
    circularProgress: {
      marginRight: '8px',
      marginLeft: 0,
    },
  }));

  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.buttonSpinner, className)}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      variant="contained"
      type={typeButton}
      onClick={onClick}
      block={block}
    >
      {loading
        ? (
          <>
            <CircularProgress className={classes.circularProgress} color="white" size={18} />
            {labelLoading}
          </>
        )
        : label}
    </Button>
  );
};

ButtonSpinner.defaultProps = {
  block: false,
  className: '',
  color: 'primary',
  disabled: false,
  fullWidth: false,
  labelLoading: '',
  loading: false,
  onClick: null,
  typeButton: 'text',
};

ButtonSpinner.propTypes = {
  block: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelLoading: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  typeButton: PropTypes.string,
};

export default ButtonSpinner;
