import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import GridItem from '../Grid/GridItem'
import GridContainer from '../Grid/GridContainer'
import FormHelperText from '@material-ui/core/FormHelperText'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import './SelectInput.css'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectInput = (props) => {
  const {
    disabled,
    elements,
    onGetOptionLabel,
    label,
    multi,
    chips,
    onSelectedValue,
    onClose,
    invalid,
    errorText,
    valueAccessor,
  } = props


  const [internalState, setInternalState] = useState([])
  

  const emitValue = (event, value) => {
    setInternalState(value)
    if(value && value[valueAccessor]) {
      onSelectedValue(value && value[valueAccessor])
    } else if (value && value.length) {
      onSelectedValue(value.map(v => v[valueAccessor]))
    } else {
      onSelectedValue('')
    }
  }


  useEffect(() => {
    if(props.multi) {
      setInternalState(props.elements.filter(e => props.value.indexOf(e[valueAccessor]) > -1));
    } else {
      const valueId = props.value && typeof props.value === 'object' ? props.value[valueAccessor] : props.value;
      setInternalState(
        props.elements.find(e => e && e[valueAccessor] === valueId)
        )
    }
  }, [props.value, props.elements])
  return (
    <GridContainer className={`select-input ${props.className}`}>
      <GridItem xs={12} sm={7} className="select-input-container">
        { multi ? (<Autocomplete
          disabled={disabled}
          options={elements}
          getOptionLabel={onGetOptionLabel}
          renderInput={params => (
            <TextField {...params} label={label} fullWidth />
          )}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name || option.value}
            </React.Fragment>
          )}
          onChange={emitValue}
          multiple={multi}
          value={internalState}
          />)
          :
          (<Autocomplete
            disabled={disabled}
            options={elements}
            getOptionLabel={onGetOptionLabel}
            renderInput={params => (
              <TextField {...params} label={label} fullWidth />
            )}
            onChange={emitValue}
            multiple={multi}
            value={internalState}
          />)
        }
        {
          invalid && (
            <FormHelperText className="helper-error">
              {errorText}
            </FormHelperText>
          )
        }
      </GridItem>
    </GridContainer>
   )
}

SelectInput.defaultProps = {
  onGetOptionLabel: option => option && (option.name || option.value),
  invalid: false,
  multi: false,
  elements: [],
  className: '',
  valueAccessor: "id",
}

SelectInput.propTypes = {
  label: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      value: PropTypes.value
    })
  ),
  value: PropTypes.any,
  onSelectedValue: PropTypes.func,
  onGetOptionSelected: PropTypes.func,
  onGetOptionLabel: PropTypes.func,
  onClose: PropTypes.func,
  invalid: PropTypes.bool,
  errorText: PropTypes.string,
  multi: PropTypes.bool,
  chips: PropTypes.bool,
  className: PropTypes.string,
  valueAccessor: PropTypes.string,
}

export default SelectInput;
