import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class PersonApiInvoker extends Component {

	static getCustomers(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customers/?show=false`, callback, callerror);
	}

	static getCustomer(customerId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customer/${customerId}`, callback, callerror);
	}

	static postCustomer(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/customer`, body, callback, callerror);
	}

	static patchCustomer(body, callback, callerror) {
		baseInvoker.patch(`${urlInvoke}/customer`, body, callback, callerror);
	}

	static getAddress(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/address`, callback, callerror);
	}

	static getPersonsAddress(personId, verified, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/persons/${personId}/address/?verified=${verified}`, callback, callerror);
	}

	static deletePersonsAddress(personId, addressId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/persons/${personId}/address/${addressId}`, callback, callerror);
	}

	static postPersonsNewAddress(personId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/address`, body, callback, callerror);
	}

	static postPersonsAddress(personId, addressId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/address/${addressId}`, body, callback, callerror);
	}

	static getPhoneTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/phoneTypes`, callback, callerror);
	}

	static getGenderTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/sex-type`, callback, callerror);
	}

	static postPersonsPhone(personId, phoneId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/phone/${phoneId}`, body, callback, callerror);
	}

	static postPersonsNewPhone(personId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/phone`, body, callback, callerror);
	}

	static getPersonPhone(personId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/persons/${personId}/phone`, callback, callerror);
	}

	static deletePersonsPhone(personId, phoneId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/persons/${personId}/phone/${phoneId}`, callback, callerror);
	}

	static getIdTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/idTypes`, callback, callerror);
	}
	
	static getEmployee(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee/${employeeId}`, callback, callerror);
	}
	static getEmployeeGeographics(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees/${employeeId}/geographic-zone`, callback, callerror);
	}

	static getNationalities(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/nationalities`, callback, callerror);
	}

	static getEmployees(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees?employee-category=PROFESSIONAL,NULL`, callback, callerror);
	}

	static getEmployeesWithGeoZone(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees-geographic-zone?employee-category=PROFESSIONAL,NULL`, callback, callerror)
	}

	static getGeographics(countryId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/countries/${countryId}/geographic-zones`, callback, callerror);
	}

	static getEmployeeEmployeeType(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees/${employeeId}/employee-type`, callback, callerror);
	}
	
	static getGeographicsCompany(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/geographic-zones`, callback, callerror);
	}

	static getEmployeesType(type, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee-type?employee-category=${type}`, callback, callerror);
	}
	static postEmployeeType(employeeId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employees/${employeeId}/employees-type`, body, callback, callerror);
	}
	static postEmployeeGeographic(employeeId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employees/${employeeId}/geographic-zone`, body, callback, callerror);
	}

	static deleteEmployeeType(personId, employeeTypeId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/employee/employee-type?employeeId=${personId}&employeeTypeId=${employeeTypeId}`, callback, callerror);
	}
	static deleteEmployeeGeographic(personId, employeeGeographicId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/employee/geographic-zone?employeeId=${personId}&geographicZoneId=${employeeGeographicId}`, callback, callerror);
	}

	static getEmployeesAddress(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees/${employeeId}/geographic-zone`, callback, callerror);
	}

	static getEmployeesByPracticetype(practiceTypeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee-by-practicetype/${practiceTypeId}`, callback, callerror);
	}

	static getEmployeesByPracticetypeAndGeographics(practiceTypeId, geographicZoneId, callback, callerror) {
		if (geographicZoneId) {
			baseInvoker.get(`${urlInvoke}/employee-by-practicetype/${practiceTypeId}?geographicZone=${geographicZoneId}`, callback, callerror);
		} else {
			baseInvoker.get(`${urlInvoke}/employee-by-practicetype/${practiceTypeId}`, callback, callerror);
		}
	}

	static postEmployeeHojaRuta(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee/hojaRuta`, body, callback, callerror);
	}

	static getAllEmployeesType(type, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee-type?employee-category=${type}`, callback, callerror);

	}
		
	static postEmployee(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee`, body, callback, callerror);
	}

	static patchEmployee(body, callback, callerror) {
		baseInvoker.patch(`${urlInvoke}/employee`, body, callback, callerror);
	}

	static getEmployeesCoordinators(callback, callerror){
		baseInvoker.get(`${urlInvoke}/employees/employee-type-name?employee-type-name=COM`, callback, callerror)
	}

}
