import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import Datetime from "react-datetime";
import customInputStyle from "../../assets/components/customInputStyle";
import '../../assets/plugins/react-datetime.css';
import './CustomInput.css';

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    placeholder,
    time,
    error,
    white,
    inputRootCustomClasses,
    multiline,
    success,
    helpText,
    onChange,
    onBlur,
    type,
    value,
    dateValue,
    defaultDateValue,
    closeOnSelect,
    dateShrink,
    min,
    max,
    pattern,
    step,
    minDate,
    iconName
  } = props;

  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    setShrink(dateShrink)
  }, [dateShrink])

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });

  var yesterday = Datetime.moment().subtract(1, 'day');
  var valid = function (current) {
    if (!min) {
      return current.isAfter(yesterday);
    } else {
      if (max) {
        return current.isSameOrAfter(min) && current.isSameOrBefore(max)
      }
      return current.isSameOrAfter(min);
    }
  };



  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (

        <InputLabel
          className={classes.labelRoot + " " + labelClasses + " " + (shrink ? "MuiInputLabel-shrink" : "")}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>

      ) : null}
      { type === 'date' ?
        minDate ?
          <Datetime
            dateFormat="DD/MM/YYYY"
            timeFormat={time ? "HH:mm" : false}
            isValidDate={valid}
            onChange={onChange}
            onBlur={onBlur}
            value={dateValue}
            defaultValue={defaultDateValue}
            closeOnSelect={closeOnSelect}
            className={props.className}
            inputProps={{
              placeholder,
              id,
              ...inputProps
            }}
          />
          :
          <Datetime
            dateFormat="DD/MM/YYYY"
            timeFormat={time ? "HH:mm" : false}
            onChange={onChange}
            onBlur={onBlur}
            value={dateValue}
            defaultValue={defaultDateValue}
            closeOnSelect={closeOnSelect}
            className={props.className}
            inputProps={{
              placeholder,
              id,
              ...inputProps
            }}
          />
        :
        iconName ?
            <div className = 'containerDivInput' >
              <div className='left'>
                <img src={iconName} width='28' />
              </div>
              <div className='center'>
                <Input
                  classes={{
                    input: inputClasses,
                    root: marginTop,
                    underline: underlineClasses
                  }}
                  id={id}
                  onChange={onChange}
                  value={value}
                  className='input-border-none'
                  multiline={multiline}
                  min={min}
                  step={step}
                  pattern={pattern}
                  type={type || 'text'}
                  inputProps={{
                  }}
                />
              </div>
            </div>
        : <Input
            classes={{
              input: inputClasses,
              root: marginTop,
              underline: underlineClasses
            }}
            id={id}
            onChange={onChange}
            value={value}
            multiline={multiline}
            min={min}
            step={step}
            pattern={pattern}
            type={type || 'text'}
            inputProps={{
              ...inputProps
            }}
          />
      }

{
  helpText !== undefined ? (
    <FormHelperText id={id + "-text"} className={helpTextClasses}>
      {helpText}
    </FormHelperText>
  ) : null
}
    </FormControl >
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  time: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  dateValue: PropTypes.any,
  defaultDateValue: PropTypes.any,
  closeOnSelect: PropTypes.bool,
  dateShrink: PropTypes.bool,
  minDate: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  className: PropTypes.any,
  iconName: PropTypes.any

};

export default withStyles(customInputStyle)(CustomInput);
