import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../SelectInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { withTranslation } from 'react-i18next';
import DateInput from '../../DateInput';
import ValidationInput from '../../ValidationInput';
import TimePickerGCalendar from '../../TimePickerGCalendar';

import SchedulePlan from '../../SchedulePlan';
import memory from '../../../config/memory.js';
import PinSource from "../../../assets/img/pin_source.png";
import PinDestination from "../../../assets/img/pin_destination.png";
import PinCurrent from "../../../assets/img/pin_current.png";

import Pending from "../../../assets/img/pending.png";
import Reject from "../../../assets/img/trasllat_cancel.png";
import Finished from "../../../assets/img/finished_trans.png";
import ToDo from "../../../assets/img/trasllat_cancel.png";

import PendingDo from "../../../assets/img/pending_do_trans.png";
import TrasladoPDF from "./TrasladoPDF.js";
import './Traslado.css'
import Button from '../../CustomButtons/Button';
import moment from 'moment';

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
    Polyline
} from 'react-google-maps'
import CustomInput from '../../CustomInput/CustomInput';
const { DrawingManager } = require("react-google-maps/lib/components/drawing/DrawingManager");

const RegularMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={14}
            center={
                { lat: parseFloat(props.center.latitude), lng: parseFloat(props.center.longitude) }}
            defaultOptions={{
                scrollwheel: false,
            }}
        >

            {props.customer &&
                <Marker key={'customer'}
                    className="routes-marker"
                    defaultLabel={''}
                    defaultIcon={
                        {
                            url: PinSource,
                            scaledSize: { width: 48, height: 48 },
                            size: { width: 48, height: 48 },
                            labelOrigin: new window.google.maps.Point(20, 15)
                        }
                    }
                    position={{ lat: parseFloat(props.customer.latitude), lng: parseFloat(props.customer.longitude) }}
                />
            }
            {props.nosocomio && props.nosocomio.latitude &&
                <Marker key={'nosocomio'}
                    className="routes-marker"
                    defaultLabel={''}
                    defaultIcon={
                        {
                            url: PinDestination,
                            scaledSize: { width: 48, height: 48 },
                            size: { width: 48, height: 48 },
                            labelOrigin: new window.google.maps.Point(20, 15)
                        }
                    }
                    position={{ lat: parseFloat(props.nosocomio.latitude), lng: parseFloat(props.nosocomio.longitude) }}
                />
            }

            {props.currentPosition &&
                <Marker key={'currentposicion'}
                    className="routes-marker"
                    defaultLabel={''}
                    defaultIcon={
                        {
                            url: PinCurrent,
                            scaledSize: { width: 48, height: 48 },
                            size: { width: 48, height: 48 },
                            labelOrigin: new window.google.maps.Point(20, 15)
                        }
                    }
                    position={props.currentPosition}
                    position={{ lat: parseFloat(props.currentPosition.latitude), lng: parseFloat(props.currentPosition.longitude) }}

                />
            }

            {props.mode && props.mode === 'inprogress' ?
                <div>
                    <Polyline
                        path={props.path1}
                        options={{
                            strokeColor: '#099107',
                            strokeOpacity: 0.7,
                            strokeWeight: 7,
                        }}
                    />
                    <Polyline
                        path={props.path2}
                        options={{
                            strokeColor: '#5DC4B9',
                            strokeOpacity: 0.7,
                            strokeWeight: 7,
                        }}
                    />
                </div>
                :
                <div>

                    {props.directions.length > 0 &&
                        <Polyline
                            path={props.directions[1]}
                            options={{
                                strokeColor: '#099107',
                                strokeOpacity: 0.7,
                                strokeWeight: 7,
                            }}
                        />
                    }
                    <Polyline
                        path={props.directions[0]}
                        options={{
                            strokeColor: props.colorStroke,
                            strokeOpacity: 0.7,
                            strokeWeight: 7,
                        }}
                    />

                </div>
            }
        </GoogleMap>
    ))
)

class DialogoTraslado extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            open: false,
            types: [{
                id: 1,
                value: t('Practice'),
            }, {
                id: 2,
                value: t('Traslado'),
            }],
            nosocomios: memory.nosocomios,
            traslados: memory.traslados,
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            address: "",
            traslado: 0,
            nosocomio: 0,
            appointmentAction: '',
            time_end_suggested: "-",
            distance_suggested: "-",
            waypoints: [],
            nosocomioObject: null,
            directions: [],
            recurrence: {},
            startTime: "02:00",
            rawPath: null,
            path1: [],
            path2: [],
            indexPosition: 0,
            currentPosition: null,
            pathExtra: null,
            time_recorrend: 720,
            distance_recorred: 5100,
        }
    }


    componentDidMount() {

        if (memory.tempService && memory.tempService.type) {
            this.setState({
                type: memory.tempService.type,
                startTime: memory.tempService.startTime,
                startDate: memory.tempService.startDate,
                recurrence: memory.tempService.recurrence
            })

            this.handleValue("traslado", memory.tempService.traslado.id);
            this.handleValue("nosocomio", memory.tempService.nosocomio.id);
        } else {
            this.handleValue("startTime", "09:00");
        }
        if (this.props.viewMode === 'apointment') {
            let interval = setInterval(this.timer, 1000);
            this.setState({ interval: interval });
        }

        if (!window.google) {
            let interval2 = setInterval(this.checkMap, 1000);
            this.setState({ interval2: interval2 });
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
        clearInterval(this.state.interval2);

    }

    checkMap = () => {
        if (window.google) {
            this.setDirection();
            clearInterval(this.state.interval2);
        }
    }

    timer = () => {
        if (this.state.rawPath != null) {
            var index = this.state.indexPosition;
            if (index >= this.state.rawPath.length) {
                clearInterval(this.state.interval);
            }
            var distance = 0;
            if (this.state.time_recorrend % 10 === 0) {
                distance = 100

            }
            if (this.state.time_recorrend % 5 === 0) {
                this.dividePath(this.state.rawPath, index + 1);
            }

            this.setState({
                distance_recorred: this.state.distance_recorred + distance,
                time_recorrend: this.state.time_recorrend + 1
            })

        }
    };


    handleValue = (key, value) => {
        this.setState({ [key]: value }, () => {
            if (key === 'startTime') {
                this.recalculateStartTime();
            }
        });
        if (key == 'nosocomio') {
            const moso = this.state.nosocomios.filter(mo => mo.id == value);
            if (moso.length > 0) {
                this.setState({
                    nosocomioObject: moso[0]
                }, () => {
                    this.setDirection();
                })
            }
        }
        this.props.handleChangeValue(key, value);
    }

    setDirection = () => {

        if (!this.props.customer || !this.state.nosocomioObject) {
            return;
        }
        var DirectionsService = null;
        try {
            DirectionsService = new window.google.maps.DirectionsService()
        } catch (error) {
            return;
        }

        var alternative = false
        if (this.props.appointment && this.props.appointment.appointmentId == 101 && this.isTodayMenus(1)) {
            alternative = true;
        }

        DirectionsService.route(
            {
                origin: { lat: parseFloat(this.props.customer.latitude), lng: parseFloat(this.props.customer.longitude) },
                destination: { lat: parseFloat(this.state.nosocomioObject.latitude), lng: parseFloat(this.state.nosocomioObject.longitude) },
                waypoints: [],
                optimizeWaypoints: true,
                provideRouteAlternatives: alternative,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                console.log(result);
                if (status === window.google.maps.DirectionsStatus.OK) {
                    var distance_suggested = "-";
                    var time_end = 0;
                    if (result.routes.length > 0 && result.routes[0].legs.length > 0) {
                        distance_suggested = result.routes[0].legs[0].distance['text'];
                        time_end = result.routes[0].legs[0].duration['value'];
                    }
                    var raw = [];
                    var extras = [];

                    if (this.props.viewMode === 'apointment') {
                        raw = result['routes'][0]['overview_path']
                        var startp = parseInt(raw.length / 2);
                        this.dividePath(raw, startp);
                    }
                    if (result['routes'].length > 1) {
                        result['routes'][1]['overview_path'].map(function (value, index, array) {
                            extras.push(value);
                        });
                    }

                    var directions = [];
                    if (result['routes'].length > 0)
                        directions.push(result['routes'][0]['overview_path'])
                    if (result['routes'].length > 1)
                        directions.push(result['routes'][1]['overview_path'])
                    if (result['routes'].length > 2)
                        directions.push(result['routes'][2]['overview_path'])

                    this.setState({
                        directions: directions,
                        distance_suggested: distance_suggested,
                        time_end: time_end,
                        rawPath: raw,
                        pathExtra: extras

                    }, () => {
                        this.recalculateStartTime();
                    })
                } else {
                    console.error(`error fetching directions ${result}`)
                }
            }
        )
    }

    dividePath = (raw, indexPath) => {
        var path1 = [];
        var path2 = [];
        var currentPosition = {}
        raw.map(function (value, index, array) {
            if (index < indexPath) {
                path1.push(value);
            } else if (index === indexPath) {
                path1.push(value)
                path2.push(value)
                console.log(value);
                currentPosition = { "latitude": value.lat(), "longitude": value.lng() }
            } else {
                path2.push(value);
            }
        })
        this.setState({
            path1: path1,
            path2: path2,
            rawPath: raw,
            indexPosition: indexPath,
            currentPosition: currentPosition,
        });
    }

    recalculateStartTime = () => {
        var start = moment(this.state.startTime, 'HH:mm');
        start = start.add(-1 * this.state.time_end, 'seconds')
        this.setState({
            time_end_suggested: start.format("HH:mm")
        });

    }
    componentWillReceiveProps(next) {
        this.setState({ open: next.open });
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        });
        this.props.onCloseModal();
    }

    selectedType(value) {
        this.setState({
            type: value,
            current: null,
            component: value === 2 ? 'traslado' : 'plan',
        });
    }

    createModule(value) {
        this.closeModal();
        this.props.onCreatedModule(value);
    }

    createPlan(value) {
        this.closeModal();
        this.props.onCreatedPlan(value);
    }

    updatePlan(value) {
        this.closeModal();
        this.props.onUpdatePlan(value);
    }

    onCreatedRecurrence = (value) => {
        this.setState({
            recurrence: value
        })
        this.props.handleChangeValue("recurrence", value);
    }

    invalidPlan = () => {
        return false;
    }

    isInvalid = () => {
        if (
            !this.state.nosocomio ||
            !this.state.traslado ||
            !this.state.startDate ||
            !this.state.recurrence ||
            !this.state.startTime) {
            return true;
        } else {
            if (!this.state.recurrence.recurrenceType) return true;
            if (this.state.recurrence.recurrenceType == 'DAY' && (!this.state.recurrence.every || this.state.recurrence.every <= 0)) return true;
            if (this.state.recurrence.recurrenceType == 'MONTH' && (!this.state.recurrence.monthRecurrence || this.state.recurrence.monthRecurrence <= 0 || this.state.recurrence.monthRecurrence > 31 || !this.state.recurrence.every || this.state.recurrence.every <= 0)) return true;
            if (this.state.recurrence.recurrenceType == 'WEEK' && (this.state.recurrence.weekRecurrence.length == 0 || !this.state.recurrence.every || this.state.recurrence.every <= 0)) return true;
            return false;
        }
    }

    formatTimeSeconds = () => {
        var totalSeconds = this.state.time_recorrend;
        var hours = Math.floor(totalSeconds / 3600) + "";
        totalSeconds %= 3600;
        var minutes = Math.floor(totalSeconds / 60) + "";
        var seconds = totalSeconds % 60 + "";
        return (hours.padStart(2, "0")) + ":" + (minutes.padStart(2, "0")) + ":" + (seconds.padStart(2, "0"));
    }

    isTodayMenus = (v) => {
        var nowmenusone = moment(new Date()).add(v * -1, 'd').format('YYYY-MM-DD');
        var event = moment(new Date(this.props.event.start)).format('YYYY-MM-DD');
        return nowmenusone === event;
    }

    toPDF = () => {
        var hora_real_retiro = "";
        var hora_real_fin = "";
        var hora_real_distance = "";
        var time_real = "";

        if (this.isTodayMenus(1)) {
            hora_real_retiro = "10:04";
            hora_real_fin = "10:29";
            hora_real_distance = "10.1 km";
            time_real = "00:25:00";
        } else if (this.isTodayMenus(2)) {
            hora_real_retiro = "10:00";
            hora_real_fin = "10:30";
            hora_real_distance = "10.4 km";
            time_real = "00:30:00";

        } else if (this.isTodayMenus(3)) {
            hora_real_retiro = "9:58";
            hora_real_fin = "10:26";
            hora_real_distance = "10.2 km";
            time_real = "00:28:00";

        } else if (this.isTodayMenus(4)) {
            hora_real_retiro = "10:02";
            hora_real_fin = "10:35";
            hora_real_distance = "10.2 km";
            time_real = "00:33:00";

        } else if (this.isTodayMenus(5)) {
            hora_real_retiro = "10:04";
            hora_real_fin = "10:32";
            hora_real_distance = "10.3 km";
            time_real = "00:28:00";

        }


        var context = {
            appointmentId: this.props.event.id,
            startDate: moment(new Date(this.props.event.start)).format('DD/MM/YYYY'),
            startTime: hora_real_retiro,
            realDate: moment(new Date(this.props.event.start)).format('DD/MM/YYYY'),
            realTime: hora_real_fin,
            address_fin:"Juan Cruz Varela 2634, Córdoba",
            trasladoName: this.props.appointment.services.traslado.value,
            customer: {
                firstName: this.props.appointment.customer.firstName,
                lastName: this.props.appointment.customer.lastName,
                address: this.props.appointment.customer.address_name,
                location: this.props.appointment.customer.location,
                province: this.props.appointment.customer.province,
                typeDocument: this.props.appointment.customer.typeDocument,
                document: this.props.appointment.customer.document,
            },
            chofer: {
                firstName: this.props.appointment.services.driver.firstName,
                lastName: this.props.appointment.services.driver.lastName,
                typeDocument: this.props.appointment.services.driver.typeDocument,
                document: this.props.appointment.services.driver.document,
            },
            nosocomio: {
                address: this.props.appointment.services.nosocomio.address,
                location: "Capital",
                province: "Córdoba",
            },
            trayecto: {
                distance: hora_real_distance,
                duration: time_real
            }
        }
        TrasladoPDF(context);
    }

    render() {
        const { t } = this.props;
        const { types } = this.state;
        var end_time = "end_time";
        var isDisabled = this.props.disabled || this.isInvalid();
        var viewMode = "short"
        var center = this.props.customer;
        if (this.props.viewMode) {
            viewMode = this.props.viewMode;
        } else {
            viewMode = this.props.appointment && this.props.appointment.appointmentStatus === 'SCHEDULES_ASSIGNED' ? "large" :
                "short"
        }
        if (this.state.currentPosition) {
            center = this.state.currentPosition;
        }

        if (viewMode == 'large') {


            var nextDay = false;
            var today = new Date();
            var dateEvent
            if (this.props.event) {
                dateEvent = new Date(this.props.event.start);
            }
            if (dateEvent > today) {
                nextDay = true
            }
            var istoday = this.isTodayMenus(0);
            var todaymenusone = this.isTodayMenus(1);
            var todaymenustwo = this.isTodayMenus(2);

            var icon = Pending
            var status = "PENDING_APPROVAL"
            if (this.props.appointment && this.props.appointment.appointmentId == 100) {
                //icon = this.props.appointment && this.props.appointment.appointmentId == 100 && (nextDay || istoday) ? Reject : Finished
                //status = this.props.appointment && this.props.appointment.appointmentId == 100 && (nextDay || istoday) ? "REJECTED" : "DONE"
                icon = Reject;
                status = "REJECTED";
            }


            if (this.props.appointment && this.props.appointment.appointmentId == 101) {
                if (istoday) {
                    icon = PendingDo;
                    status = "IN_PROGRESS";
                } else if (nextDay) {
                    icon = PendingDo
                    status = "APPROVED";
                } else if (todaymenusone || todaymenustwo) {
                    icon = Finished
                    status = "DONE";
                } else {
                    icon = Finished;
                    status = "DONE";
                }
            }

            var color = "#099107";
            if (nextDay) {
                color = "#5DC4B9";
            }

            if (this.props.appointment && this.props.appointment.appointmentId == 101 && this.isTodayMenus(1)) {
                color = "#5DC4B9";
            }

            if (this.props.appointment && this.props.appointment.appointmentId == 100) {
                color = "#5DC4B9";
            }

            var hora_real_retiro = "-";
            var hora_real_fin = "-";
            var hora_real_distance = "-";
            var finished = false;
            if (this.props.appointment && this.props.appointment.appointmentId == 101) {
                if (this.isTodayMenus(0)) {
                    hora_real_retiro = "10:04";
                    color = "#5DC4B9";
                }
                if (this.isTodayMenus(1)) {
                    hora_real_retiro = "10:04";
                    hora_real_fin = "10:29";
                    hora_real_distance = "10.1 km";
                    finished = true;
                } else if (this.isTodayMenus(2)) {
                    hora_real_retiro = "10:00";
                    hora_real_fin = "10:30";
                    hora_real_distance = "10.4 km";
                    finished = true;

                } else if (this.isTodayMenus(3)) {
                    hora_real_retiro = "9:58";
                    hora_real_fin = "10:26";
                    hora_real_distance = "10.2 km";
                    finished = true;

                } else if (this.isTodayMenus(4)) {
                    hora_real_retiro = "10:02";
                    hora_real_fin = "10:35";
                    hora_real_distance = "10.2 km";
                    finished = true;

                } else if (this.isTodayMenus(5)) {
                    hora_real_retiro = "10:04";
                    hora_real_fin = "10:32";
                    hora_real_distance = "10.3 km";
                    finished = true;
                }
            }
            return (
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6} className="componentType">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    labelText={t('service.traslado_detail')}
                                    disabled={true}
                                    value={memory.tempService.traslado.value}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    labelText={t('service.scheduleId')}
                                    disabled={true}
                                    value={this.props.event.id}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    labelText={t('service.driver')}
                                    disabled={true}
                                    value={memory.tempService.driver ? memory.tempService.driver.value : "-"}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    labelText={t('service.start_date')}
                                    disabled={true}
                                    onChangeValue={value => this.handleValue('startDate', value)}
                                    value={`${moment(this.props.event.start).format('DD/MM/YYYY')}`}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} className='no-padding'>
                                <ValidationInput
                                    text={t('service.customer.address')}
                                    disabled={true}
                                    inputProps={{ style: { width: '100%' } }}
                                    value={this.props.appointment.customer.address_name}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    labelText={`${t('service.startTimeProg')}`}
                                    disabled={true}
                                    labelProps={{ style: { width: '100%', marginTop: "-10px" } }}
                                    value={this.state.time_end_suggested}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    labelText={t('service.startTimeProgReal')}
                                    disabled={true}
                                    onChangeValue={value => this.handleValue('startDate', value)}
                                    value={hora_real_retiro}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} className='no-padding'>
                                <ValidationInput
                                    text={t('service.nosocomio.address')}
                                    elements={this.state.nosocomios}
                                    disabled={true}
                                    value={memory.tempService.nosocomio.value + " - " + memory.tempService.nosocomio.address}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className='timeInput'>
                                <CustomInput
                                    labelText={t('service.endTimeProg')}
                                    elements={this.state.nosocomios}
                                    disabled={true}
                                    labelProps={{ style: { width: '100%', marginTop: "-10px" } }}
                                    value={memory.tempService.startTime}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    type="text"
                                    disabled={true}
                                    labelText={`${t('service.endTimeReal')}`}
                                    value={hora_real_fin}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    type="text"
                                    disabled={true}
                                    labelText={`${t('service.distance_suggested')}`}
                                    value={this.state.distance_suggested}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    type="text"
                                    disabled={true}
                                    labelText={`${t('service.distance_real')}`}
                                    value={hora_real_distance}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} className='no-padding'>
                                <ValidationInput
                                    type="text"
                                    iconName={icon}
                                    disabled={true}
                                    inputProps={{ style: { width: '100%' } }}
                                    text={`${t('service.status')}`}
                                    value={`${t(status)}`}
                                />

                            </GridItem>
                        </GridContainer>

                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} >

                        <GridItem xs={12} sm={12} md={12}>
                            <RegularMap
                                customer={this.props.customer}
                                location={this.state.currentLocation}
                                directions={this.state.directions}
                                waypoints={this.state.waypoints}
                                mode=''
                                center={center}
                                colorStroke={color}
                                nosocomio={this.state.nosocomioObject}
                                googleMapURL="/googlemap"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={
                                    <div
                                        style={{
                                            height: `500px`,
                                            borderRadius: '6px',
                                            overflow: 'hidden',
                                            width: `100%`,
                                        }}
                                    />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </GridItem>
                        <GridContainer style={{textAlign: 'right'}}>
                        <GridItem xs={12} sm={12} md={12}>
                                {finished &&
                                    <Button
                                        onClick={() => this.toPDF()}
                                        style={{textAlign: 'right'}}
                                        color="warning">

                                        {t('service.download.pdf')}
                                    </Button>
                                }
                            </GridItem>
                        </GridContainer>

                    </GridItem>

                </GridContainer>
            );

        } else if (viewMode === 'short') {
            var min, max;
            if (!this.props.disabled) {
                min = moment(new Date(this.props.provider.dateStart)).format('YYYY-MM-DD');
                max = moment(new Date(this.props.provider.dateEnd)).format('YYYY-MM-DD');
            }
            return (
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6} className="componentType">
                        <GridItem xs={12} sm={12} md={12}>
                            <SelectInput
                                label={t('service.type')}
                                elements={types}
                                onSelectedValue={(value) => this.handleValue("type", value)}
                                disabled={true}
                                value={2}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <SelectInput
                                label={t('service.traslado')}
                                elements={this.state.traslados}
                                disabled={this.props.disabled}
                                onSelectedValue={(value) => this.handleValue("traslado", value)}
                                value={this.state.traslado}
                            />
                        </GridItem>
                        <GridItem xs={12} className="margin-left">
                            <h4 className="label-services">{t('service.destino')}</h4>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <SelectInput
                                label={t('service.nosocomio')}
                                elements={this.state.nosocomios}
                                disabled={this.props.disabled}
                                onSelectedValue={(value) => this.handleValue("nosocomio", value)}
                                value={this.state.nosocomio}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <ValidationInput
                                type="text"
                                text={`${t('service.address')}`}
                                disabled={true}
                                value={this.state.nosocomioObject ? this.state.nosocomioObject.address : ""}
                            />
                        </GridItem>
                        <GridContainer>
                            <GridItem xs={5} sm={5} md={5}>
                                <DateInput
                                    text={t('service.start_date')}
                                    disabled={this.props.disabled}
                                    min={min}
                                    max={max}
                                    minDate
                                    onChangeValue={value => this.handleValue('startDate', value)}
                                    value={this.state.startDate}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className='timeInput'>
                                <TimePickerGCalendar
                                    text={t('service.time_end_destination')}
                                    value={this.state.startTime}
                                    name={end_time}
                                    disabled={this.props.disabled}
                                    onSelectedValue={(value) => this.handleValue('startTime', value)}
                                    defaultValue="02:00"
                                    invalid={!this.state.startTime} />
                            </GridItem>

                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <SchedulePlan
                                    isTraslado={true}
                                    insideModule={false}
                                    current={this.state.recurrence}
                                    indexArrayDays={0}
                                    disabled={this.props.disabled}
                                    invalidPlan={this.invalidPlan}
                                    onCreatedRecurrence={this.onCreatedRecurrence}
                                    className="schedule-plan-component"
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} >
                        <GridContainer>

                            <GridItem xs={6} sm={6} md={6}>
                                <ValidationInput
                                    type="text"
                                    disabled={this.props.disabled}
                                    text={`${t('service.distance')}`}
                                    value={this.state.distance_suggested}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <ValidationInput
                                    type="text"
                                    disabled={this.props.disabled}
                                    text={`${t('service.time_start_destination')}`}
                                    value={this.state.time_end_suggested}
                                    disabled={true}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <RegularMap
                                customer={this.props.customer}
                                location={this.state.currentLocation}
                                directions={this.state.directions}
                                waypoints={this.state.waypoints}
                                nosocomio={this.state.appointment ? this.state.appointment.nosocomio : this.state.nosocomioObject}
                                googleMapURL="/googlemap"
                                mode=''
                                center={center}
                                colorStroke={'#5DC4B9'}

                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={
                                    <div
                                        style={{
                                            height: `500px`,
                                            borderRadius: '6px',
                                            overflow: 'hidden',
                                            width: `100%`,
                                        }}
                                    />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </GridItem>
                        {!this.props.appointment &&
                            <GridContainer className='button-translado'>
                                <GridItem xs={6} sm={6} md={6}>
                                    <Button
                                        disabled={this.props.disabled}
                                        onClick={() => this.props.openDrivers()}
                                        color="primary">

                                        {t('service.driver.assign')}
                                    </Button>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>

                                    <Button
                                        color="violet"
                                        disabled={isDisabled}
                                        onClick={() => this.props.saveService()}
                                    >
                                        {t('service.save')}
                                    </Button>
                                </GridItem>

                            </GridContainer>
                        }

                    </GridItem>

                </GridContainer>
            );

        } else {

            var date = new Date();
            date = moment(date).format('DD-MM-YYYY');

            console.log(this.state.currentPosition);



            var distance_recorred_str = this.state.distance_recorred / 1000 + " Km";
            var time_recorred_str = this.formatTimeSeconds();
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} className="componentType">
                        <GridContainer>
                            <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                    labelText={t('service.scheduleId')}
                                    disabled={true}
                                    value={this.props.event.id}
                                />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <CustomInput
                                    labelText={t('service.start_date')}
                                    disabled={this.props.disabled}
                                    onChangeValue={value => this.handleValue('startDate', value)}
                                    value={date}
                                />
                            </GridItem>

                            <GridItem xs={3} sm={3} md={3}>
                                <ValidationInput
                                    text={t('service.customer.address')}
                                    disabled={true}
                                    inputProps={{ style: { width: '100%' } }}
                                    value={this.props.appointment.customer.address_name}
                                />
                            </GridItem>

                            <GridItem xs={2} sm={2} md={2}>
                                <CustomInput
                                    labelText={t('service.startTimeProgReal')}
                                    disabled={true}
                                    onChangeValue={value => this.handleValue('startDate', value)}
                                    value={this.state.time_end_suggested}
                                />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <CustomInput
                                    labelText={t('service.endTimeEstimated')}
                                    disabled={true}
                                    labelProps={{ style: { width: '100%', marginTop: "-10px" } }}

                                    onChangeValue={value => this.handleValue('startDate', value)}
                                    value={this.state.time_end_suggested}
                                    value={memory.tempService.startTime}
                                />
                            </GridItem>


                            <GridItem xs={3} sm={3} md={3}>
                                <CustomInput
                                    labelText={t('service.traslado_detail')}
                                    disabled={true}
                                    value={memory.tempService.traslado.value}
                                />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <CustomInput
                                    labelText={t('service.driver')}
                                    disabled={true}
                                    value={memory.tempService.driver ? memory.tempService.driver.value : "-"}
                                />
                            </GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                                <ValidationInput
                                    text={t('service.nosocomio.address')}
                                    elements={this.state.nosocomios}
                                    disabled={true}
                                    value={memory.tempService.nosocomio.value + " - " + memory.tempService.nosocomio.address}
                                />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2} className='no-padding'>
                                <ValidationInput
                                    text={t('service.distance_recorred')}
                                    disabled={true}
                                    value={distance_recorred_str}
                                />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2} className='no-padding'>
                                <ValidationInput
                                    text={t('service.time_recorred')}
                                    disabled={true}
                                    value={time_recorred_str}
                                />
                            </GridItem>
                        </GridContainer>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} >
                        <GridContainer>


                        </GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <RegularMap
                                customer={this.props.customer}
                                location={this.state.currentLocation}
                                directions={this.state.directions}
                                waypoints={this.state.waypoints}
                                center={center}
                                path1={this.state.path1}
                                path2={this.state.path2}
                                nosocomio={this.props.appointment ? this.props.appointment.services.nosocomio : {}}
                                googleMapURL="/googlemap"
                                mode='inprogress'
                                colorStroke={'#5DC4B9'}

                                pathExtra={this.state.pathExtra}
                                currentPosition={this.state.currentPosition}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={
                                    <div
                                        style={{
                                            height: `500px`,
                                            borderRadius: '6px',
                                            overflow: 'hidden',
                                            width: `100%`,
                                        }}
                                    />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </GridItem>

                    </GridItem>

                </GridContainer>
            );

        }

    }
}

DialogoTraslado.defaultProps = {
    onGetModuleTypes: () => [],
    onGetPracticeTypes: () => [],
}

DialogoTraslado.propTypes = {
    open: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onCreatedModule: PropTypes.func,
    onCreatedPlan: PropTypes.func,
    onGetModuleTypes: PropTypes.func,
    onGetPracticeTypes: PropTypes.func,
    current: PropTypes.object,
    action: PropTypes.string,
}

export default withTranslation()(DialogoTraslado);
