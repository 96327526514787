import React, { Component } from 'react'
import $ from 'jquery'
import 'timepicker'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import moment from 'moment'
import './TimePickerGCalendar.css'

class TimePickerGCalendar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '09:00',
    }
    this.timePickerRef = React.createRef()
  }

  componentDidMount() {
    if (this.props.value && this.props.value !== '') {
      this.setState({
        value: moment(this.props.value, 'HH:mm').format('HH:mm'),
      })
    }

    this.initTimePicker()
  }

  initTimePicker() {
    let defaultOptions = {
      timeFormat: 'H:i',
      maxTime: '23:59pm',
    }

    if (this.props.beginLimit) {
      defaultOptions = {
        ...defaultOptions,
        minTime: this.props.beginLimit,
      }
    }

    if (this.props.endLimit) {
      defaultOptions = {
        ...defaultOptions,
        maxTime: this.props.endLimit,
      }
    }
    $(`#${this.props.name}`).timepicker(defaultOptions)
    $(this.timePickerRef.current).on('change', e => this.handleChange(e))
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== '' && prevProps.value !== this.props.value) {
      this.setState({
        value: moment(this.props.value, 'HH:mm').format('HH:mm'),
      })
    }
  }

  handleChange = (e) => {
    const regex = /^[0-9:\b]{1,5}$/
    const { value } = e.target;

    if (!value || regex.test(value)) {
      this.setState({ value });
    }
  }

  handleOnBlur = (e) =>{
    this.handleChange(e);
    this.props.onSelectedValue(e.target.value)
  }

  render() {
    return (
      <GridContainer className="select-input">
        <GridItem xs={12} sm={7}>
          <FormControl fullWidth>
            <TextField
              ref={this.timePickerRef}
              id={this.props.name}
              label={this.props.text}
              error={this.props.invalid}
              helperText={this.props.errorText}
              onChange={e => this.handleChange(e)}
              value={this.state.value}              
              defaultValue={this.props.defaultValue}
              onBlur={(e) => this.handleOnBlur(e)}
              disabled={this.props.disabled}
              inputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
    )
  }
}

TimePickerGCalendar.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
  defaultValue: PropTypes.string,
  invalid: PropTypes.bool,
  errorText: PropTypes.string,
  onSelectedValue: PropTypes.func.isRequired,
  beginLimit: PropTypes.string,
  endLimit: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default TimePickerGCalendar
