import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import GridItem from '../Grid/GridItem';
import './RadioInput.css';

class RadioInput extends Component {
    constructor(props) {
        super(props);
    }

    change(e) {
        this.props.onChangeValue(e.target.value);
    }

    render() {
        const { children, components, value, className, elements, inputProps, title } = this.props;
        return (
            <GridItem xs={12} className="radio-input">
                <FormControl component="fieldset">
                    {title && (<FormLabel className="radio-input-title" component="legend" disabled={inputProps && inputProps.disabled}>{title}</FormLabel>)}
                    <RadioGroup
                        aria-label="radio"
                        name="radio-group"
                        value={value}
                        className={className}
                        onChange={(e) => this.change(e)}
                    >
                        {elements &&
                            elements.map(e => {
                                return (
                                    <div className="radio-input-group" key={`radio-option-${e.id}`}>
                                        <FormControlLabel
                                            value={e.id}
                                            label={e.label || e.value}
                                            control={<Radio color="primary" disabled={inputProps && inputProps.disabled}/>}
                                        />
                                        {e.component && components ? components(e.id, inputProps && inputProps.disabled) : e.component && children}
                                    </div>
                                )
                            })
                        }
                    </RadioGroup>
                </FormControl>
            </GridItem>
        )
    }
}

RadioInput.defaultProps = {
    children: null,
    components: () => {},
    inputProps: {},
    onChangeValue: () => {},
    value: '',
    title: '',
}

RadioInput.propTypes = {
    children: PropTypes.any,
    components: PropTypes.func,
    elements: PropTypes.array.isRequired,
    inputProps: PropTypes.object,
    onChangeValue: PropTypes.func,
    value: PropTypes.string,
    title: PropTypes.string,
}

export default RadioInput;
