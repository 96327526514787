import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../CustomDialog';
import SelectInput from '../../SelectInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { withTranslation } from 'react-i18next';
import DialogTraslado from './DialogTraslado.js';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CardFooter from '../../Card/CardFooter';
import Button from '../../CustomButtons/Button';
import memory from '../../../config/memory';

class ModalDetailServiceTraslado extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            open: false,
            types: [{
                id: 1,
                value: t('Practice'),
            }, {
                id: 2,
                value: t('Traslado'),
            }],
            drivers: memory.drivers,
            traslados: memory.traslados,
            type: '',
            module: '',
            component: null,
            current: null,
            action: '',
            appointmentAction: '',
            openDrivers: false,
        }
    }


    componentWillReceiveProps(next) {

        
        if (next.open) {
            this.setState({ open: next.open });
            if (memory.tempService && memory.tempService.type) {
                this.setState({
                    component: "traslado",
                    type: 2
                })
            }
        }
    }

    closeModal() {
        this.setState({
            open: false,
            type: '',
            component: null,
            current: null,
        });
        this.props.onCloseModal();
    }


    closeModalDrivers() {
        this.setState({
            openDrivers: false,
        });
    }

    selectedType(value) {
        this.setState({
            type: value,
            current: null,
            component: value === 2 ? 'traslado' : null,
        });
    }

    onChangeItem = (value) => {
        alert(value);
    }

    openDrivers = () => {
        this.setState({
            openDrivers: true
        })
    }

    handleChangeValue = (key, value) => {
        this.setState({ [key]: value });
    }

    saveService = () => {

        const nosocomioObject =  memory.nosocomios.filter(mo => mo.id === this.state.nosocomio);
        const transladoObject =  memory.traslados.filter(tra => tra.id === this.state.traslado);

        const driverObject =  memory.drivers.filter(drive => drive.id === this.state.driver);
        var recurrence = this.state.recurrence;
        recurrence.endType = "FINISH";
        var body = {
            type: this.state.type,
            traslado: transladoObject[0],
            nosocomio: nosocomioObject[0],
            driver: driverObject.length > 0 ? driverObject[0] : null,
            startDate: this.state.startDate,
            startTime: this.state.startTime,
            recurrence: recurrence
        }
        memory.tempService = body;
        this.closeModal();
    }

    render() {
        const { t, items, customer, onGetModuleTypes, onGetPracticeTypes } = this.props;
        const { action, appointmentAction, component, current, open, type, types } = this.state;
        var widthModal = "xs";
        if (this.state.component) {
            widthModal = "md";
        }
        return (
            <div>
                <CustomDialog maxWidth={widthModal} title={t('service.title')} open={open} onClose={() => this.closeModal()}>
                    <div>
                        {!this.state.component ?
                            <GridContainer className={component === 'module' ? 'ps-child modules-form' : 'ps-child plan-form'}>
                                <GridItem xs={12} sm={12} md={12} className="componentType">
                                    <SelectInput
                                        disabled={appointmentAction === 'assign_agenda' || (appointmentAction === 'edit' && action !== 'create')}
                                        label={t('appointment.new.schedule.modal.type')}
                                        elements={types}
                                        onSelectedValue={(value) => this.selectedType(value)}
                                        value={type}
                                    />
                                </GridItem>
                            </GridContainer>
                            :
                            <GridContainer className={component === 'module' ? 'ps-child modules-form' : 'ps-child plan-form'}>
                                <GridItem xs={12} sm={12} md={12} className="componentType">
                                    {this.state.component == 'traslado' ?
                                        <DialogTraslado
                                            openDrivers={this.openDrivers}
                                            customer={this.props.customer}
                                            appointment={this.props.appointment}
                                            event={this.props.event}
                                            viewMode={this.props.viewMode}
                                            provider={this.props.provider}
                                            disabled={this.props.disabled}
                                            saveService={this.saveService}
                                            handleChangeValue={this.handleChangeValue}
                                        />

                                        : <div />
                                    }
                                </GridItem>
                            </GridContainer>
                        }
                    </div>
                    
                </CustomDialog>

                <CustomDialog maxWidth={'xs'} title={t('service.drivers.title')} open={this.state.openDrivers} onClose={() => this.closeModalDrivers()}>
                    <div>
                        <GridContainer className={component === 'module' ? 'ps-child modules-form' : 'ps-child plan-form'}>
                            <GridItem xs={12} sm={12} md={12} className="componentType">
                                <SelectInput
                                    label={t('service.traslado')}
                                    elements={this.state.traslados}
                                    disabled={true}
                                    value={this.state.traslado}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} className="componentType">
                                <SelectInput
                                    label={t('service.drivers.title')}
                                    elements={this.state.drivers}
                                    onSelectedValue={(value) => this.handleChangeValue("driver", value)}
                                    value={this.state.driver}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                    <CardFooter className="schedule-form-submit buttonFooter">
                        <Button className="buttonPlusFooter"
                            onClick={() => this.closeModalDrivers()}
                            color="success"
                        >
                            {t('service.drivers.save')}
                        </Button>
                    </CardFooter>

                </CustomDialog>
            </div>
        )
    }
}

ModalDetailServiceTraslado.defaultProps = {
    onGetModuleTypes: () => [],
    onGetPracticeTypes: () => [],
}

ModalDetailServiceTraslado.propTypes = {
    open: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onCreatedModule: PropTypes.func,
    onCreatedPlan:  PropTypes.func,
    onGetModuleTypes: PropTypes.func,
    onGetPracticeTypes: PropTypes.func,
    current: PropTypes.object,
    action: PropTypes.string,
}

export default withTranslation()(ModalDetailServiceTraslado);
