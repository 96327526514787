const memory = {
    "appointments": [{ 
        "customer": 
            { "customer": 
                { 
                    "id": 10, "value": "Susana Lopresti", "customerId": "2463404" 
                }, 
                "customerId": "2463404", 
                "address": 3, 
                "zone": "Capital", 
                "geographicZoneId": 543, 
                "location": "Capital", 
                "province": "Córdoba", 
                "firstName": "Susana",
                "lastName": "Lopresti",
                "typeDocument": "DNI",
                "document": "",
                "latitude": "-31.43582442067", 
                "longitude": "-64.21854105985976", 
                "address_name": "Cumbres Negras 2295" 
            }, 
            "financier": {"financierId":1531,"affiliateId":"14797498", "financierName": "Compañia Financiadora"},
            "startDate": "", 
            "endDate": "", 
            "services": { 
                    "type": 2, 
                    "traslado": { "id": 1, "value": "Traslado estándar" }, 
                    "nosocomio": { "id": 1, "value": "Hospital Aleman", "address": "Roma 726, Córdoba", "latitude": -31.407258600503155, "longitude": -64.16541940205802 }, 
                    "startDate": new Date().setDate(new Date().getDate() - 5), 
                    "startTime": "08:20", 
                    "driver": {
                        id: 1,
                        value: "Juan Díaz",
                        firstName: "Juan",
                        lastName: "Diaz",
                        typeDocument: "DNI",
                        document: "24471358",

                    },
                    "recurrence": { "recurrenceType": "DAY", "every": 1, "quantityIntraday": null, "finish": "1", "finishOcurrences": "", "finishDate": "", "monthRecurrence": "", "weekRecurrence": [], "endType": "FINISH" } }, 
                    "identification": "6508349", 
                    "curstomerObject": { "customer": { "id": 3, "value": "Santiago Beker", "customerId": "2463404" }, "customerId": "2463404", "address": 3, "zone": "Capital", "geographicZoneId": 543, "location": "Capital", "province": "Córdoba", "latitude": "-31.3525536", "longitude": "-64.1803925", "address_name": "Avenida 11 De Setiembre 3500" 
                }, 
                "createdDateTime": "2021-02-26T14:26:44.078Z", 
                "appointmentStatus": "SCHEDULES_ASSIGNED", 
                "provider": { 
                    "provider": 1, 
                    "dateStart": "2022-02-26T00:00:00.000", 
                    "dateEnd": "2022-02-27T00:00:00.000" 
                }, 
                "appointmentId": 100, 
                "schedules": [] 
        },        
        { "customer": 
            { "customer": 
                { 
                    "id": 813, "value": "Dario Gomez", "customerId": "2463404" 
                }, 
                "customerId": "2463404", 
                "address": 3, 
                "zone": "Capital", 
                "geographicZoneId": 543, 
                "location": "Capital", 
                "province": "Córdoba", 
                "firstName": "Dario",
                "lastName": "Gomez",
                "typeDocument": "DNI",
                "document": "20200224",
                "latitude": "-31.42268899678269", 
                "longitude": "-64.177281417530615", 
                "address_name": "Bv. Illia 651" 
            }, 
                "financier": {"financierId":1531,"affiliateId":"14797498", "financierName": "Compañia Financiadora"},
                "startDate": "", 
                "endDate": "", 
                "services": { 
                    "type": 2, 
                    "driver": {
                            id: 1,
                            value: "Juan Díaz",
                            firstName: "Juan",
                            lastName: "Diaz",
                            typeDocument: "DNI",
                            document: "24471358",

                    },
                    "traslado": { "id": 1, "value": "Traslado estándar" }, 
                    "nosocomio": { "id": 3, "value": "Sanatorio Salguero", "address": "Juan Cruz Varela 2606, Córdoba", "latitude": -31.366305876793653, "longitude": -64.22714730230462 }, 
                    "startDate": new Date().setDate(new Date().getDate() - 5), 
                    "startTime": "10:30", 
                    "recurrence": { "recurrenceType": "DAY", "every": 1, "quantityIntraday": null, "finish": "1", "finishOcurrences": "", "finishDate": "", "monthRecurrence": "", "weekRecurrence": [], "endType": "FINISH" } }, 
                    "identification": "20200414", 
                    "curstomerObject": { "customer": { "id": 3, "value": "Santiago Beker", "customerId": "2463404" }, "customerId": "2463404", "address": 3, "zone": "Capital", "geographicZoneId": 543, "location": "Capital", "province": "Córdoba", "latitude": "-31.3525536", "longitude": "-64.1803925", "address_name": "Avenida 11 De Setiembre 3500" 
                }, 
                "createdDateTime": "2021-02-26T14:26:44.078Z", 
                "appointmentStatus": "SCHEDULES_ASSIGNED", 
                "provider": { 
                    "provider": 1, 
                    "dateStart": "2021-02-26T00:00:00.000", 
                    "dateEnd": "2021-02-27T00:00:00.000" 
                }, 
                "appointmentId": 101, 
                "schedules": [] 
            },
    ],

    "nosocomios": [{
        id: 1,
        value: "Hospital Aleman",
        address: "Roma 726, Córdoba",
        latitude: -31.407258600503155,
        longitude: -64.16541940205802,
    }, {
        id: 2,
        value: "Hospital Córdoba",
        address: "Av. Fernando Fader 3606, Córdoba",
        latitude: -31.37869277025569,
        longitude: -64.22737623097132,
    }, {
        id: 3,
        value: "Sanatorio Salguero",
        address: "Juan Cruz Varela 2606, Córdoba",
        latitude: -31.36632419857128,
        longitude: -64.2271258444639,
    }],
    "currentBookings": [],

    "drivers": [
        {
            id: 1,
            value: "Juan Díaz",
            firstName: "Juan",
            lastName: "Díaz",
            email: "jdiaz102@gmail.com",
            zoneGeographics: "Capital",
            vehicle: "Hundai H1 (AX-325-AA)",
            identification: "24471358",
            birthDate: "01/10/1997",
            gender: "Masculino",
            documentType: "DNI - Documento Nacional de identidad",
            marca: "Hundai",
            nationality: "Argentino/a",
            model: "H1",
            main: "Si",
            patente: "AX-325-AA",
            apto: "Si",
            year: 2019,
            type: "Van",
            typephone: "Principal",
            description: "sin descripcion",
            numberphone: "(0358) 154499360",
            addresstype: "Particular",
            street: "Marcelo T. De Alvear",
            number: "480",
            city: "Capital",
            province: "Córdoba",
            zone: "Capital",
        },

        {
            id: 2,
            value: "Cristian Bolomo",
            firstName: "Cristian",
            lastName: "Bolomo",
            email: "cbolomo60@gmail.com",
            zoneGeographics: "Mendiolaza",
            vehicle: "Peugeot Partner (AT-454-SG)",
            identification: "20542126",
            birthDate: "12/14/1956",
            gender: "Masculino",
            documentType: "DNI - Documento Nacional de identidad",
            marca: "Peugeot",
            nationality: "Argentino/a",
            model: "Partner",
            main: "Si",
            patente: "AT-454-SG",
            apto: "Si",
            year: 2020,
            type: "Furgoneta",
            typephone: "Principal",
            description: "sin descripcion",
            numberphone: "(351) 155827261",
            addresstype: "Particular",
            street: "Manuel Pizarro",
            number: "2520",
            city: "Mendiolaza",
            province: "Córdoba",
            zone: "Mendiolaza",
        },
        {
            id: 3,
            value: "Facundo Cardozo",
            firstName: "Facundo",
            lastName: "Cardozo",
            email: "fcardozo73@gmail.com",
            zoneGeographics: "Capital",
            vehicle: "Ford Focus (AU-558-AV)",
            identification: "22036830",
            birthDate: "02/22/2001",
            gender: "Masculino",
            documentType: "DNI - Documento Nacional de identidad",
            marca: "Ford",
            nationality: "Argentino/a",
            model: "Focus",
            main: "Si",
            patente: "AU-558-AV",
            apto: "No",
            year: 2021,
            type: "Sedan",
            typephone: "Principal",
            description: "sin descripcion",
            numberphone: "(351) 155827261",
            addresstype: "Particular",
            street: "Santa Rosa",
            number: "669",
            city: "Capital",
            province: "Córdoba",
            zone: "Capital",

        },
    ],
    "traslados": [
        {
            id: 1,
            value: "Traslado estándar"
        },
        {
            id: 2,
            value: "Traslado con rampa"
        },
        {
            id: 3,
            value: "Traslado con ambulancia"
        },
    ],
    "tempService": null,
}

export default memory;


